
export default class AlertRow {
    constructor(id = 0, created, updated, amount = 0, productcode = '', productname = '', productsize = '', productcolor = '', distributionpointnumber = 0, distributionpointname = '', alertlistidentifier = '', goalamount = 0, orderlimit = 0, alertlimit = 0, editedbyapplicationuserid = '00000000-0000-0000-0000-000000000000', editedbyapplicationusername = '') {
        this.Id = id;
        this.Created = created;
        this.Updated = updated;
        this.Amount = amount;
        this.DistributionPointNumber = distributionpointnumber;  
        this.DistributionPointName = distributionpointname;  
        this.ProductCode = productcode;
        this.ProductName = productname; 
        this.ProductSize = productsize; 
        this.ProductColor = productcolor; 
        this.AlertListIdentifier = alertlistidentifier;
        this.GoalAmount = goalamount;
        this.OrderLimit = orderlimit;
        this.AlertLimit = alertlimit;
        this.EditedByApplicationUserId = editedbyapplicationuserid;
        this.EditedByApplicationUserName = editedbyapplicationusername;
      }    
}

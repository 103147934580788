import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import resources from "../resources";
import "../styles/StorageBalanceTable.css";
import BaseTable from "../components/BaseTable.component";
import moment from "moment";
import { amountsinstorageActions } from "../actions/amountsinstorage.actions";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import Transition from "semantic-ui-react/dist/commonjs/modules/Transition";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import ProductPointLimit from "../models/ProductPointLimit.model";
import { productpointlimitActions } from "../actions/productpointlimit.actions";
import { int32 } from "../constants/int.constants";
import { distributionpointActions } from "../actions/distributionpoint.actions";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";

class StorageBalanceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      sortcolumn: "",
      sortorder: "",
      productpointlimits: [],
      unsavedchanges: false,
      distributionpoint: 0,
    };
    this.loadData = this.loadData.bind(this);
    this.sortby = this.sortby.bind(this);
    this.addValue = this.addValue.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.saveChangesWithTimeout = this.saveChangesWithTimeout.bind(this);
    this.handleDistributionPointChange =
      this.handleDistributionPointChange.bind(this);
  }

  handleBlur(name, e, storageAmount) {
    let value = e.target.value.replace(",", ".");
    let changedValue = window.isNaN(value) ? 0 : parseFloat(value);

    e.target.value = changedValue.toString().replace(".", ",");

    //if (changedValue !== storageAmount[name]){
    this.addValue(name, changedValue, storageAmount);
    //}
  }
  /**
   * Add user entered value to pointlimits
   * @param {*} name
   * @param {*} value
   * @param {*} storageAmount
   */
  addValue(name, value, storageAmount) {
    let pointLimit = {
      ...new ProductPointLimit(),
      ...{
        Id: storageAmount.PointLimitId ? storageAmount.PointLimitId : 0,
        ProductCode: storageAmount.ProductCode,
        ProductSize: storageAmount.ProductSize,
        ProductColor: storageAmount.ProductColor,
        DistributionPointNumber: storageAmount.DistributionPointNumber,
        GoalAmount: storageAmount.GoalAmount,
        OrderLimit: storageAmount.OrderLimit,
        AlertLimit: storageAmount.AlertLimit,
        FridayGoalAmount: storageAmount.FridayGoalAmount,
      },
    };

    this.setState((prevState, props) => {
      let tryFindPointLimit;
      //Find by pointlimitId, if exists
      if (storageAmount.PointLimitId) {
        tryFindPointLimit = prevState.productpointlimits.find(
          (i) => i.Id === storageAmount.PointLimitId
        );
      }
      //Find by productcode and point
      else {
        tryFindPointLimit = prevState.productpointlimits.find(
          (i) =>
            i.ProductCode === storageAmount.ProductCode &&
            i.ProductSize === storageAmount.ProductSize &&
            i.ProductColor === storageAmount.ProductColor &&
            i.DistributionPointNumber === storageAmount.DistributionPointNumber
        );
      }

      pointLimit = tryFindPointLimit
        ? { ...tryFindPointLimit }
        : {
            ...pointLimit,
            ...{
              Id: storageAmount.PointLimitId ? storageAmount.PointLimitId : 0,
              ProductCode: storageAmount.ProductCode,
              ProductSize: storageAmount.ProductSize,
              ProductColor: storageAmount.ProductColor,
              DistributionPointNumber: storageAmount.DistributionPointNumber,
              GoalAmount: storageAmount.GoalAmount,
              OrderLimit: storageAmount.OrderLimit,
              AlertLimit: storageAmount.AlertLimit,
              FridayGoalAmount: storageAmount.FridayGoalAmount,
            },
          };

      pointLimit[name] = value;

      return {
        unsavedchanges: true,
        productpointlimits:
          tryFindPointLimit ||
          prevState.productpointlimits.find(
            (item) =>
              item.ProductCode === pointLimit.ProductCode &&
              item.ProductSize === pointLimit.ProductSize &&
              item.ProductColor === pointLimit.ProductColor &&
              item.DistributionPointNumber ===
                pointLimit.DistributionPointNumber
          )
            ? prevState.productpointlimits.map((item) => {
                if (
                  item.ProductCode === pointLimit.ProductCode &&
                  item.ProductSize === pointLimit.ProductSize &&
                  item.ProductColor === pointLimit.ProductColor &&
                  item.DistributionPointNumber ===
                    pointLimit.DistributionPointNumber
                )
                  return { ...pointLimit };
                else return item;
              })
            : [...prevState.productpointlimits, pointLimit],
      };
    });
  }
  /**
   * Save pointlimit changes
   */
  saveChanges(reload) {
    if (this.state.productpointlimits.length > 0) {
      let tasks = [];

      tasks.push(
        this.props
          .dispatch(
            productpointlimitActions.addorupdate([
              ...this.state.productpointlimits,
            ])
          )
          .then((pointLimits) => {
            this.setState({
              unsavedchanges: false,
              productpointlimits: [...pointLimits],
            });
          })
      );

      Promise.all(tasks).then((i) => {
        if (reload) {
          //    this.loadData();
        }
      });
    }
  }
  componentWillUnmount() {
    this.props.dispatch(amountsinstorageActions.clear());
  }
  componentDidMount() {
    this.loadData();
    this.loadDistributionPoints();
  }
  loadDistributionPoints() {
    this.props.dispatch(distributionpointActions.get());
  }
  handleDistributionPointChange(e, obj) {
    if (this.state.distributionpoint !== obj.value) {
      if (
        this.state.unsavedchanges &&
        !window.confirm(resources.UNSAVED_CHANGES_WILL_BE_DISCARDED)
      ) {
        e.target.value = this.state.distributionpoint;
        e.preventDefault();
        return false;
      }

      this.setState(
        {
          distributionpoint: obj.value,
          unsavedchanges: false,
          productpointlimits: [],
        },
        () => {
          if (this.distributionTimeout && this.distributionTimeout !== null) {
            clearTimeout(this.distributionTimeout);
            this.distributionTimeout = null;
          }
          this.distributionTimeout = setTimeout(() => {
            this.loadData();
          }, 500);
        }
      );
    }
  }
  sortby(col, order) {
    this.setState({ sortcolumn: col, sortorder: order }, () => {
      this.loadData();
    });
  }
  constructHeaders() {
    return [
      { value: resources.DISTRIBUTION_NUMBR, name: "DistributionPointNumber" },
      { value: resources.LATEST_TIMESTAMP, name: "Created" },
      { value: resources.SIZE_PRODUCTCODE, name: "ProductCode" },
      { value: resources.PRODUCT_DESCRIPTION, name: "ProductName" },
      { value: resources.AMOUNT_IN_SHELF, width: 1, name: "Amount" },
      { value: resources.GOALAMOUNT, width: 1, name: "GoalAmount" },
      { value: resources.ORDERLIMIT, width: 1, name: "OrderLimit" },
      { value: resources.ALERTLIMIT, width: 1, name: "AlertLimit" },
      {
        value: resources.FRIDAY_GOALAMOUNT,
        width: 1,
        name: "FridayGoalAmount",
      },
      { value: resources.ORDERAMOUNT, width: 1 },
    ];
  }

  constructRows(amountsinstorages) {
    if (this.props.loadingAmountsInStorages) {
      return [
        {
          cells: [
            {
              value: "",
            },
            {
              value: "",
            },
            {
              value: "",
            },
            {
              value: "",
            },
            {
              value: "",
            },
            {
              value: "",
            },
            {
              value: "",
            },
            {
              value: "",
            },
            {
              value: "",
            },
          ],
        },
      ];
    } else {
      return amountsinstorages.map((storageAmount, index) => {
        let tryFindPointLimit;
        //Find by pointlimitId, if exists
        if (storageAmount.PointLimitId) {
          tryFindPointLimit = this.state.productpointlimits.find(
            (i) => i.Id === storageAmount.PointLimitId
          );
        }
        //Find by productcode, size, color and point
        else {
          tryFindPointLimit = this.state.productpointlimits.find(
            (i) =>
              i.ProductCode === storageAmount.ProductCode &&
              i.ProductSize === storageAmount.ProductSize &&
              i.ProductColor === storageAmount.ProductColor &&
              i.DistributionPointNumber ===
                storageAmount.DistributionPointNumber
          );
        }

        let amount = storageAmount.Amount;
        let goalamount = tryFindPointLimit
          ? tryFindPointLimit.GoalAmount
          : storageAmount.GoalAmount;
        let orderlimit = tryFindPointLimit
          ? tryFindPointLimit.OrderLimit
          : storageAmount.OrderLimit;
        let alertlimit = tryFindPointLimit
          ? tryFindPointLimit.AlertLimit
          : storageAmount.AlertLimit;
        let fridaygoalamount = tryFindPointLimit
          ? tryFindPointLimit.FridayGoalAmount
          : storageAmount.FridayGoalAmount;

        //Amount = tavoitemäärä – saldot hyllyssä = tilausmäärä
        let orderamount = goalamount - amount < 0 ? 0 : goalamount - amount;

        //1 - Monday, 2 - Tuesday, 3 - Wednesday, 4 - Thursday, 5 - Friday, 6 - Saturday, 7 - Sunday
        //If weekend, then compare to fridaygoal
        if (moment().isoWeekday() >= 5 && fridaygoalamount > 0)
          orderamount =
            fridaygoalamount - amount < 0 ? 0 : fridaygoalamount - amount;

        //Check if amount is over limit
        //    if friday then orderlimit is fridaygoalamount, else use orderlimit to check if amount is greater
        if (
          amount >=
          (moment().isoWeekday() >= 5 && fridaygoalamount > 0
            ? fridaygoalamount
            : orderlimit)
        )
          orderamount = 0;

        return {
          cells: [
            {
              value:
                storageAmount.DistributionPointNumber +
                " - " +
                storageAmount.DistributionPointName,
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
              },
            },
            {
              value: storageAmount.Created
                ? moment(storageAmount.Created).format("D.M.YYYY HH:mm")
                : "",
            },
            {
              value: storageAmount.ProductCode,
            },
            {
              value:
                storageAmount.ProductName +
                (storageAmount.ProductColor
                  ? ", " + storageAmount.ProductColor
                  : "") +
                (storageAmount.ProductSize
                  ? ", " + storageAmount.ProductSize
                  : ""),
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
              },
            },
            {
              negative: amount < alertlimit,
              warning: amount < orderlimit && amount >= alertlimit,
              custom: (
                <Input
                  type="number"
                  transparent
                  className="fullwidth"
                  value={amount.toFixed(2)}
                  readOnly
                  tabIndex="-1"
                />
              ), //
              style: { whiteSpace: "nowrap" },
            },
            {
              custom: (
                <Input
                  type="text"
                  transparent
                  className="fullwidth"
                  pattern="[0-9]+([.|,][0-9]{1,2})?"
                  defaultValue={goalamount.toString().replace(".", ",")}
                  key={
                    storageAmount.DistributionPointNumber +
                    "," +
                    storageAmount.ProductCode +
                    "," +
                    storageAmount.ProductSize +
                    "," +
                    storageAmount.ProductColor +
                    ",GoalAmount"
                  }
                  disabled={
                    storageAmount.DistributionPointNumber === 0 ||
                    this.props.user.Roles.includes("Viewer")
                  }
                  readOnly={
                    storageAmount.DistributionPointNumber === 0 ||
                    this.props.user.Roles.includes("Viewer")
                  }
                  title={
                    storageAmount.DistributionPointNumber === 0
                      ? resources.CANNOT_EDIT_FOR_THIS_DISTRIBUTIONPOINT
                      : ""
                  }
                  onFocus={(e) => e.target.select()}
                  onMouseUp={(e) => {
                    return false;
                  }}
                  onBlur={(e) =>
                    this.handleBlur("GoalAmount", e, storageAmount)
                  }
                  icon={
                    tryFindPointLimit &&
                    tryFindPointLimit.GoalAmount !== storageAmount.GoalAmount
                      ? "write"
                      : undefined
                  }
                />
              ),
              style: { whiteSpace: "nowrap" },
            },
            {
              warning: amount < orderlimit,
              custom: (
                <Input
                  type="text"
                  transparent
                  className="fullwidth"
                  pattern="[0-9]+([.|,][0-9]{1,2})?"
                  defaultValue={orderlimit.toString().replace(".", ",")}
                  key={
                    storageAmount.DistributionPointNumber +
                    "," +
                    storageAmount.ProductCode +
                    "," +
                    storageAmount.ProductSize +
                    "," +
                    storageAmount.ProductColor +
                    ",OrderLimit"
                  }
                  disabled={
                    storageAmount.DistributionPointNumber === 0 ||
                    this.props.user.Roles.includes("Viewer")
                  }
                  readOnly={
                    storageAmount.DistributionPointNumber === 0 ||
                    this.props.user.Roles.includes("Viewer")
                  }
                  title={
                    storageAmount.DistributionPointNumber === 0
                      ? resources.CANNOT_EDIT_FOR_THIS_DISTRIBUTIONPOINT
                      : ""
                  }
                  onFocus={(e) => e.target.select()}
                  onMouseUp={(e) => {
                    return false;
                  }}
                  onBlur={(e) =>
                    this.handleBlur("OrderLimit", e, storageAmount)
                  }
                  icon={
                    tryFindPointLimit &&
                    tryFindPointLimit.OrderLimit !== storageAmount.OrderLimit
                      ? "write"
                      : undefined
                  }
                />
              ),
              style: { whiteSpace: "nowrap" },
            },
            {
              negative: amount < alertlimit,
              custom: (
                <Input
                  type="text"
                  transparent
                  className="fullwidth"
                  pattern="[0-9]+([.|,][0-9]{1,2})?"
                  defaultValue={alertlimit.toString().replace(".", ",")}
                  key={
                    storageAmount.DistributionPointNumber +
                    "," +
                    storageAmount.ProductCode +
                    "," +
                    storageAmount.ProductSize +
                    "," +
                    storageAmount.ProductColor +
                    ",AlertLimit"
                  }
                  disabled={
                    storageAmount.DistributionPointNumber === 0 ||
                    this.props.user.Roles.includes("Viewer")
                  }
                  readOnly={
                    storageAmount.DistributionPointNumber === 0 ||
                    this.props.user.Roles.includes("Viewer")
                  }
                  title={
                    storageAmount.DistributionPointNumber === 0
                      ? resources.CANNOT_EDIT_FOR_THIS_DISTRIBUTIONPOINT
                      : ""
                  }
                  onFocus={(e) => e.target.select()}
                  onMouseUp={(e) => {
                    return false;
                  }}
                  onBlur={(e) =>
                    this.handleBlur("AlertLimit", e, storageAmount)
                  }
                  icon={
                    tryFindPointLimit &&
                    tryFindPointLimit.AlertLimit !== storageAmount.AlertLimit
                      ? "write"
                      : undefined
                  }
                />
              ),
              style: { whiteSpace: "nowrap" },
            },
            {
              //If friday,sat,sun and amount is lower than friday
              negative: moment().isoWeekday() > 5 && amount < fridaygoalamount,
              custom: (
                <Input
                  type="text"
                  transparent
                  className="fullwidth"
                  pattern="[0-9]+([.|,][0-9]{1,2})?"
                  defaultValue={fridaygoalamount.toString().replace(".", ",")}
                  key={
                    storageAmount.DistributionPointNumber +
                    "," +
                    storageAmount.ProductCode +
                    "," +
                    storageAmount.ProductSize +
                    "," +
                    storageAmount.ProductColor +
                    ",FridayGoalAmount"
                  }
                  disabled={
                    storageAmount.DistributionPointNumber === 0 ||
                    this.props.user.Roles.includes("Viewer")
                  }
                  readOnly={
                    storageAmount.DistributionPointNumber === 0 ||
                    this.props.user.Roles.includes("Viewer")
                  }
                  title={
                    storageAmount.DistributionPointNumber === 0
                      ? resources.CANNOT_EDIT_FOR_THIS_DISTRIBUTIONPOINT
                      : ""
                  }
                  onFocus={(e) => e.target.select()}
                  onMouseUp={(e) => {
                    return false;
                  }}
                  onBlur={(e) =>
                    this.handleBlur("FridayGoalAmount", e, storageAmount)
                  }
                  icon={
                    tryFindPointLimit &&
                    tryFindPointLimit.FridayGoalAmount !==
                      storageAmount.FridayGoalAmount
                      ? "write"
                      : undefined
                  }
                />
              ),
              style: { whiteSpace: "nowrap" },
            },
            {
              value: orderamount.toFixed(2).replace(".", ","),
            },
          ],
        };
      });
    }
  }
  /**
   * Call saveChanges when timeout exceeds, clear timeout if calling again before timeout
   */
  saveChangesWithTimeout() {
    if (this.saveChangesTimeout && this.saveChangesTimeout !== null) {
      clearTimeout(this.saveChangesTimeout);
      this.saveChangesTimeout = null;
    }
    this.saveChangesTimeout = setTimeout(() => {
      this.saveChanges();
    }, 500);
  }
  loadData(start = 0, rows = int32.MAX) {
    let { search, sortcolumn, sortorder, distributionpoint } = this.state;

    this.props.dispatch(
      amountsinstorageActions.getbyDistributionpoint(
        distributionpoint,
        start,
        rows,
        search,
        sortcolumn,
        sortorder
      )
    );
  }
  getPointOptions(distributionpoints) {
    let points = [{ key: "storagebalance_e", text: "", value: "" }];

    points = [
      ...points,
      ...distributionpoints.map((item, index) => {
        return {
          key: "storagebalance-point_" + index,
          text: item.PointNumber + " - " + item.PointName,
          value: item.PointNumber,
        };
      }),
    ];

    return points;
  }
  render() {
    const {
      loadingAmountsInStorages,
      amountsinstorages,
      loadingProductPointLimit,
      loadingDistributionPoints,
      distributionpoints,
    } = this.props;
    let { productpointlimits, unsavedchanges, distributionpoint } = this.state;
    return (
      <React.Fragment>
        {!this.props.user.Roles.includes("Viewer") && (
          <Menu fixed="bottom" compact size="huge">
            <Menu.Menu position="right">
              <Menu.Item>
                <Transition
                  animation="tada"
                  duration={500}
                  visible={productpointlimits.length > 0}
                >
                  <Button
                    positive
                    icon="save"
                    labelPosition="left"
                    type="button"
                    content={
                      (unsavedchanges === true
                        ? "(" + productpointlimits.length + ") "
                        : "") + resources.SAVE
                    }
                    onClick={() => this.saveChanges()}
                    disabled={loadingProductPointLimit}
                    loading={loadingProductPointLimit}
                  />
                </Transition>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        )}

        <Menu secondary stackable style={{ padding: "0", margin: "0" }}>
          <Menu.Menu position="right" style={{ padding: "0", margin: "0" }}>
            <Menu.Item style={{ padding: "0", margin: "0" }}>
              <Dropdown
                key={"storagebalance-distributionpointselect"}
                selection
                value={distributionpoint === 0 ? "" : distributionpoint}
                loading={loadingDistributionPoints || loadingAmountsInStorages}
                options={this.getPointOptions(distributionpoints)}
                placeholder={resources.SELECT_DISTRIBUTIONPOINT}
                onChange={this.handleDistributionPointChange}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <BaseTable
          color={
            unsavedchanges === true
              ? "orange"
              : productpointlimits.length > 0
              ? "green"
              : undefined
          }
          loading={loadingAmountsInStorages}
          headers={this.constructHeaders()}
          rows={this.constructRows(amountsinstorages)}
          sortby={this.sortby}
          pageSize={int32.MAX}
          mobilecolumns={5}
          laptopcolumns={8}
        />
        <br />
        <br />
        <br />
      </React.Fragment>
    );
  }
}

StorageBalanceTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loadingAmountsInStorages: PropTypes.bool.isRequired,
  amountsinstorages: PropTypes.array.isRequired,
  loadingProductPointLimit: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  distributionpoints: PropTypes.array,
  loadingDistributionPoints: PropTypes.bool,
};

function mapStateToProps(state) {
  const { distributionpoints, loadingDistributionPoints } =
    state.distributionpoint;
  const { amountsinstorages, loadingAmountsInStorages } =
    state.amountsinstorage;
  const { loadingProductPointLimit } = state.productpointlimit;
  return {
    amountsinstorages,
    loadingAmountsInStorages,
    loadingProductPointLimit,
    distributionpoints,
    loadingDistributionPoints,
  };
}

export default connect(mapStateToProps)(StorageBalanceTable);

import { distributionpointConstants }  from '../constants/distributionpoint.constants';
import { alertActions } from './alert.actions';
import resources from '../resources';
import DistributionPointService from '../services/distributionpoint.service';
 
export const distributionpointActions = {  
    create,
    get,
    getById,
    update,
    remove
};
/**
 * Create object action
 * @param {*} data 
 */
function create(data) {
    return dispatch => {
        dispatch(request(data));

        return new DistributionPointService().create(data)
                        .then(
                            distributionpoint => {
                                dispatch(success(distributionpoint));
                                dispatch(alertActions.success(resources.SAVE_SUCCESS));
                                return distributionpoint;
                            },
                            error => {
                                dispatch(failure(error, data));
                                dispatch(alertActions.error(resources.SAVE_FAILED + ': ' + error));
                                return error;
                            }
                        );
    };

    function request(distributionpoint) { return { type: distributionpointConstants.CREATE_DISTRIBUTIONPOINT_REQUEST, distributionpoint } }
    function success(distributionpoint) { return { type: distributionpointConstants.CREATE_DISTRIBUTIONPOINT_SUCCESS, distributionpoint } }
    function failure(error, distributionpoint) { return { type: distributionpointConstants.CREATE_DISTRIBUTIONPOINT_FAILURE, error, distributionpoint } }
}

/**
 * Query object array action
 * @param {*} start 
 * @param {*} rows 
 * @param {*} search 
 * @param {*} sortcolumn 
 * @param {*} sortorder 
 */
function get(start, rows, search, sortcolumn, sortorder) {
    return dispatch => {
        dispatch(request());

        new DistributionPointService().query(start,rows,search,sortcolumn,sortorder)
                        .then(
                            distributionpoints => dispatch(success(distributionpoints)),
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.GET_FAILED + ': ' +error));
                            }
                        );
    };

    function request() { return { type: distributionpointConstants.GET_DISTRIBUTIONPOINT_REQUEST } }
    function success(distributionpoints) { return { type: distributionpointConstants.GET_DISTRIBUTIONPOINT_SUCCESS, distributionpoints } }
    function failure(error) { return { type: distributionpointConstants.GET_DISTRIBUTIONPOINT_FAILURE, error } }
}

/**
 * Query object 
 * @param {*} id
 */
function getById(id) {
    return dispatch => {
        dispatch(request(id));

        new DistributionPointService().getById(id)
                        .then(
                            distributionpoint => dispatch(success(distributionpoint)),
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.GET_FAILED + ': ' + error));
                            }
                        );
    };

    function request(id) { return { type: distributionpointConstants.GETBYID_DISTRIBUTIONPOINT_REQUEST, id} }
    function success(distributionpoint) { return { type: distributionpointConstants.GETBYID_DISTRIBUTIONPOINT_SUCCESS, distributionpoint } }
    function failure(error) { return { type: distributionpointConstants.GETBYID_DISTRIBUTIONPOINT_FAILURE, error } }
}
/**
 * Update
 * @param {*} id
 */
function update(id,data) {
    return dispatch => {
        dispatch(request(data));

        return new DistributionPointService().update(id,data)
                        .then(
                            distributionpoint => {
                                dispatch(success(distributionpoint));
                                dispatch(alertActions.success(resources.SAVE_SUCCESS));
                                return distributionpoint; 
                            },
                            error => {
                                dispatch(failure(error, data));
                                dispatch(alertActions.error(resources.SAVE_FAILED + ': ' + error));
                                return error; 
                            }
                        );
    };

    function request(distributionpoint) { return { type: distributionpointConstants.UPDATE_DISTRIBUTIONPOINT_REQUEST, distributionpoint } }
    function success(distributionpoint) { return { type: distributionpointConstants.UPDATE_DISTRIBUTIONPOINT_SUCCESS, distributionpoint } }
    function failure(error, distributionpoint) { return { type: distributionpointConstants.UPDATE_DISTRIBUTIONPOINT_FAILURE, error, distributionpoint } }
}

/**
 * Delete
 * @param {*} id
 */
function remove(id) {
    return dispatch => {
        dispatch(request());

        return new DistributionPointService().remove(id)
                        .then(
                            distributionpoint => {
                                dispatch(success(distributionpoint));
                                dispatch(alertActions.success(resources.DELETE_SUCCESS));
                                return distributionpoint;
                            },
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.DELETE_FAILED + ': ' + error));
                                return error;
                            }
                        );
    };

    function request() { return { type: distributionpointConstants.REMOVE_DISTRIBUTIONPOINT_REQUEST, id } }
    function success() { return { type: distributionpointConstants.REMOVE_DISTRIBUTIONPOINT_SUCCESS, id } }
    function failure(error) { return { type: distributionpointConstants.REMOVE_DISTRIBUTIONPOINT_FAILURE, error } }
}

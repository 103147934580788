export const logConstants = {
    
    GET_LOG_REQUEST: 'GET_LOG_REQUEST',
    GET_LOG_SUCCESS: 'GET_LOG_SUCCESS',
    GET_LOG_FAILURE: 'GET_LOG_FAILURE',
    
    QUERY_LOG_REQUEST: 'QUERY_LOG_REQUEST',
    QUERY_LOG_SUCCESS: 'QUERY_LOG_SUCCESS',
    QUERY_LOG_FAILURE: 'QUERY_LOG_FAILURE',

    GETBYID_LOG_REQUEST: 'GETBYID_LOG_REQUEST',
    GETBYID_LOG_SUCCESS: 'GETBYID_LOG_SUCCESS',
    GETBYID_LOG_FAILURE: 'GETBYID_LOG_FAILURE',
    
    REMOVE_OLD_LOGS_REQUEST: 'REMOVE_OLD_LOGS_REQUEST',
    REMOVE_OLD_LOGS_SUCCESS: 'REMOVE_OLD_LOGS_SUCCESS',
    REMOVE_OLD_LOGS_FAILURE: 'REMOVE_OLD_LOGS_FAILURE',

};

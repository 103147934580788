
export default class ProductPointLimit {
    constructor(id = 0, created, updated, epc = '', productcode = '', productsize = '', productcolor = '', distributionpointnumber = 0, goalamount = '',  orderlimit = '', alertlimit = '', pointlimitid = 0, editedbyapplicationuserid = '00000000-0000-0000-0000-000000000000', editedbyapplicationusername = '') {
        this.Id = id;
        this.Created = created;
        this.Updated = updated;
        this.ProductCode = productcode;
        this.ProductSize = productsize;
        this.ProductColor = productcolor;
        this.DistributionPointNumber = distributionpointnumber;   
        this.EditedByApplicationUserId = editedbyapplicationuserid;
        this.EditedByApplicationUserName = editedbyapplicationusername;

        this.GoalAmount = goalamount;
        this.OrderLimit = orderlimit;
        this.AlertLimit = alertlimit;

        this.PointLimitId = pointlimitid;
      }    
}
import configuration from '../configuration';
import {loginConstants}  from '../constants/login.constants';
 
let token = JSON.parse(localStorage.getItem(configuration.TOKEN_LOCALSTORAGE_KEY));
const initialState = token ? 
                    { 
                      loggedIn: true, 
                      loggingIn:false, 
                      token:token 
                    } : {
                      loggedIn: false, 
                      loggingIn:false
                    };

export function login(state = initialState, action) {
  switch (action.type) {
    case loginConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        token: action.token
      };
    case loginConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false,
        token: action.token
      };
    case loginConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        loggingIn: false,
        error: action.error
      };
    case loginConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import resources from "../resources";
import "../styles/OrderRowTable.css";
import BaseTable from "../components/BaseTable.component";
import moment from "moment";
import { orderrowActions } from "../actions/orderrow.actions";
import { CSVLink } from "react-csv/lib";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import { distributionpointActions } from "../actions/distributionpoint.actions";
import { int32 } from "../constants/int.constants";
import JsBarcode from "jsbarcode";
import configuration from "./../configuration";

class OrderRowTable extends Component {
  constructor(props) {
    super(props);
    this.csvDownloadRef = React.createRef();
    this.state = {
      search: "",
      sortcolumn: "",
      sortorder: "",
      latest: false,
      distributionpoint: 0,
      loadingCsv: false,
      allLoaded: null,
    };
    this.loadData = this.loadData.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.sortby = this.sortby.bind(this);
    this.handleDistributionPointChange =
      this.handleDistributionPointChange.bind(this);
    this.loadAll = this.loadAll.bind(this);
    this.generateBarcode = this.generateBarcode.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.latest !== nextProps.latest) {
      //Change state based on props
      return { latest: nextProps.latest };
    }

    //No changes
    return null;
  }
  componentDidMount() {
    this.loadData();
    this.loadDistributionPoints();
  }
  sortby(col, order) {
    this.setState({ sortcolumn: col, sortorder: order }, () => {
      this.loadData();
    });
  }
  constructHeaders() {
    return [
      {
        value: resources.DISTRIBUTION_NUMBR,
        width: 2,
        name: "DistributionPointNumber",
      },
      { value: resources.LATEST_TIMESTAMP, width: 2, name: "Updated" },
      { value: resources.SIZE_PRODUCTCODE, width: 1, name: "ProductCode" },
      { value: resources.PRODUCT_DESCRIPTION, name: "ProductName" },
      { value: resources.ORDERAMOUNT, width: 1, name: "Amount" },
      { value: resources.DELIVERED, width: 1, style: { display: "none" } },
      {
        value: resources.EXTRA_INFO,
        style: { display: "none", width: "100%" },
      },
    ];
  }

  constructRows(orderrows) {
    return orderrows.map((orderrow, index) => {
      return {
        cells: [
          {
            value:
              orderrow.DistributionPointNumber +
              " - " +
              orderrow.DistributionPointName,
          },
          {
            value: moment(orderrow.Updated).format("D.M.YYYY HH:mm"),
          },
          {
            value: orderrow.ProductCode,
          },
          {
            value:
              orderrow.ProductName +
              // (orderrow.ProductColor ? ", " + orderrow.ProductColor : '') +
              (orderrow.ProductSize ? ", " + orderrow.ProductSize : ""),
            style: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              minWidth: "200px",
            },
          },
          {
            value: orderrow.OrderAmount.toFixed(2).replace(".", ","),
            negative:
              orderrow.OrderAmount > 0 && orderrow.Amount < orderrow.AlertLimit,
          },
          {
            value: "",
            style: { display: "none" },
          },
          {
            value: "",
            style: { display: "none" },
          },
        ],
      };
    });
  }

  handleSearchChange = (e, { value }) => {
    if (this.state.search !== value) {
      this.setState({ search: value }, () => {
        if (this.searchTimeout && this.searchTimeout !== null) {
          clearTimeout(this.searchTimeout);
          this.searchTimeout = null;
        }
        this.searchTimeout = setTimeout(() => {
          this.loadData();
        }, 500);
      });
    }
  };
  loadAll() {
    let { search, sortcolumn, sortorder, latest, distributionpoint } =
      this.state;

    this.setState({ loadingCsv: true }, () => {
      orderrowActions
        .getDataByDistributionPoint(
          latest,
          distributionpoint === 0 ? undefined : distributionpoint,
          search,
          sortcolumn,
          sortorder
        )
        .then((response) => {
          this.setState({ allLoaded: response }, () => {
            if (
              this.csvDownloadRef &&
              this.csvDownloadRef.current &&
              this.csvDownloadRef.current.link
            )
              this.csvDownloadRef.current.link.click();

            this.setState({ allLoaded: null, loadingCsv: false });
          });
        });
    });
  }
  loadData(start = 0, rows) {
    let { search, sortcolumn, sortorder, latest, distributionpoint } =
      this.state;
    const { pageSize } = this.props;

    if (pageSize && rows === undefined) rows = pageSize;
    else if (rows === undefined) rows = 15;

    if (latest)
      return this.props.dispatch(
        orderrowActions.getLatestByDistributionPoint(
          distributionpoint === 0 ? undefined : distributionpoint,
          start,
          rows,
          search,
          sortcolumn,
          sortorder
        )
      );
    else
      return this.props.dispatch(
        orderrowActions.getByDistributionPoint(
          distributionpoint === 0 ? undefined : distributionpoint,
          start,
          rows,
          search,
          sortcolumn,
          sortorder
        )
      );
  }
  loadDistributionPoints() {
    this.props.dispatch(distributionpointActions.get());
  }
  getCsv(alertrows) {
    let head = this.constructHeaders();

    let result = [head.map((i) => i.value)];

    let rows = this.constructRows(alertrows);

    rows.forEach((r) => {
      result.push(r.cells.map((i) => ("csv" in i ? i.csv : i.value)));
    });

    return result;
  }
  generateBarcode() {
    const { distributionpoint } = this.state;
    const { distributionpoints } = this.props;
    let point = distributionpoints.find(
      (i) => i.PointNumber === distributionpoint
    );

    console.log("configuration.barcodeoptions", configuration.barcodeoptions);
    if (point)
      JsBarcode("#order-barcode", point.PointCode, {
        ...configuration.barcodeoptions,
        text: point.PointCode,
      });
  }
  handleDistributionPointChange(e, obj) {
    if (this.state.distributionpoint !== obj.value) {
      if (this.searchTimeout && this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }

      this.setState({ distributionpoint: obj.value }, () => {
        if (this.distributionTimeout && this.distributionTimeout !== null) {
          clearTimeout(this.distributionTimeout);
          this.distributionTimeout = null;
        }

        this.generateBarcode();
        this.distributionTimeout = setTimeout(() => {
          this.loadData();
        }, 500);
      });
    }
  }
  getPointOptions(distributionpoints) {
    let points = [{ key: "projectstate_e", text: "", value: "" }];

    points = [
      ...points,
      ...distributionpoints.map((item, index) => {
        return {
          key: "orderrow-point_" + index,
          text: item.PointNumber + " - " + item.PointName,
          value: item.PointNumber,
        };
      }),
    ];

    return points;
  }
  createOrderList(distributionpoint) {
    if (
      distributionpoint &&
      distributionpoint !== 0 &&
      distributionpoint !== undefined &&
      distributionpoint !== ""
    ) {
      this.props.dispatch(
        orderrowActions.createByDistributionpoint(distributionpoint)
      );

      if (this.searchTimeout && this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      this.searchTimeout = setTimeout(() => {
        this.loadData();
      }, 3000);
    }
  }
  openPrint() {
    window.print();
  }
  render() {
    const {
      orderrows,
      latestorderrows,
      loadingOrderRows,
      distributionpoints,
      loadingDistributionPoints,
      pageSize,
      key,
      loadingOrderRow,
      create,
    } = this.props;
    let { latest, distributionpoint, allLoaded, loadingCsv } = this.state;

    const orderrowsData = latest ? latestorderrows : orderrows;
    const csvData = pageSize === int32.MAX ? orderrowsData : allLoaded;

    let componentKey = key === undefined ? "OrderRowTable" : key;
    let point = distributionpoints.find(
      (i) => i.PointNumber === distributionpoint
    );
    return (
      <div>
        <table className="ui table very basic show-on-print">
          <tbody>
            <tr>
              {distributionpoint !== 0 &&
                distributionpoint !== undefined &&
                distributionpoint !== "" &&
                distributionpoints.length > 0 && (
                  <td>
                    <strong>{resources.DISTRIBUTIONPOINT}</strong>
                    {": " + distributionpoint + " - " + point.PointName}
                  </td>
                )}
              {orderrowsData && orderrowsData.length > 0 && (
                <td>
                  <strong>{resources.LATEST_TIMESTAMP}</strong>
                  {": " +
                    moment(
                      orderrowsData.reduce((prev, current) => {
                        return moment(prev.Created).isAfter(
                          moment(current.Created)
                        )
                          ? prev
                          : current;
                      }).Created
                    ).format("D.M.YYYY HH:mm")}
                </td>
              )}
            </tr>
          </tbody>
        </table>

        <svg
          id="order-barcode"
          className="order-barcode show-on-print"
          alt={distributionpoint}
        />

        <Menu secondary stackable>
          <Menu.Menu position="left">
            <Menu.Item>
              <Dropdown
                key={"orderrow-distributionpointselect"}
                selection
                loading={
                  loadingDistributionPoints ||
                  loadingOrderRows ||
                  loadingOrderRow
                }
                options={this.getPointOptions(distributionpoints)}
                placeholder={resources.SELECT_DISTRIBUTIONPOINT}
                onChange={this.handleDistributionPointChange}
              />
            </Menu.Item>
            {create === true && (
              <Menu.Item>
                <Button
                  icon
                  size="mini"
                  primary
                  disabled={
                    loadingOrderRow ||
                    distributionpoint === 0 ||
                    distributionpoint === undefined ||
                    distributionpoint === ""
                  }
                  loading={loadingOrderRow}
                  onClick={() => this.createOrderList(distributionpoint)}
                >
                  <Icon name="save" /> {resources.CREATE_NEW_ORDERLIST}
                </Button>
              </Menu.Item>
            )}
          </Menu.Menu>
          <Menu.Menu position="right">
            <Menu.Item>
              <Button
                icon
                basic
                size="mini"
                onClick={this.openPrint}
                disabled={
                  distributionpoint === 0 ||
                  distributionpoint === undefined ||
                  distributionpoint === ""
                }
              >
                <Icon name="print" /> {resources.PRINT}
              </Button>
            </Menu.Item>
            <Menu.Item>
              {csvData === null && (
                <Button
                  icon
                  basic
                  size="medium"
                  onClick={this.loadAll}
                  loading={loadingCsv}
                  disabled={loadingCsv}
                >
                  <Icon name="file excel outline" /> {resources.EXPORT_TO_CSV}
                </Button>
              )}
              {csvData !== null && (
                <CSVLink
                  ref={this.csvDownloadRef}
                  filename={resources.ORDER_LISTS + ".csv"}
                  data={this.getCsv(csvData)}
                  separator={";"}
                >
                  <Button icon basic size="medium">
                    <Icon name="file excel outline" /> {resources.EXPORT_TO_CSV}
                  </Button>
                </CSVLink>
              )}
            </Menu.Item>
            <Menu.Item>
              <Input
                icon="search"
                placeholder={resources.SEARCH + "..."}
                onChange={this.handleSearchChange}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <BaseTable
          key={"orderrowtable-" + componentKey}
          parentkey={"orderrowtable-" + componentKey}
          className={"orderrowtable-print"}
          loading={loadingOrderRows}
          headers={this.constructHeaders()}
          rows={this.constructRows(orderrowsData)}
          sortby={this.sortby}
          pageSize={pageSize || 15}
          loadEntries={latest ? undefined : this.loadData}
        />
      </div>
    );
  }
}

OrderRowTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loadingOrderRows: PropTypes.bool.isRequired,
  orderrows: PropTypes.array,
  latestorderrows: PropTypes.array,
  latest: PropTypes.bool,
  pageSize: PropTypes.number,
  key: PropTypes.string,
  distributionpoints: PropTypes.array,
  loadingDistributionPoints: PropTypes.bool,
  create: PropTypes.bool,
  loadingOrderRow: PropTypes.bool,
};

function mapStateToProps(state) {
  const { distributionpoints, loadingDistributionPoints } =
    state.distributionpoint;
  const { orderrows, latestorderrows, loadingOrderRow, loadingOrderRows } =
    state.orderrow;
  return {
    orderrows,
    latestorderrows,
    loadingOrderRows,
    distributionpoints,
    loadingDistributionPoints,
    loadingOrderRow,
  };
}

export default connect(mapStateToProps)(OrderRowTable);

import Grud from './grud.service';
import { grudService } from './base.grud.service';

class AmountsInStorageService extends Grud {
    constructor() {
      super('amountsinstorage');
    }
    /**
     * query with parameters by distributionpoint
     * @param {*} distributionPointNumber 
     * @param {*} start 
     * @param {*} rows 
     * @param {*} search 
     * @param {*} sortcolumn 
     * @param {*} sortorder 
     */
    querybyDistributionpoint(distributionPointNumber,start, rows, search, sortcolumn, sortorder){

      let url = this.apiUrl;

      if (url.endsWith('/') === false)
        url += '/';
  
      url += 'distributionpoint/';
  
      if (distributionPointNumber)
        url += `${encodeURIComponent(distributionPointNumber)}`;
      else
        url += '-';
  
      return grudService.query(url, start, rows, search, sortcolumn, sortorder);

    }
  
}
export default AmountsInStorageService;
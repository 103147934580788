export const inventoryrowConstants = {
    CREATE_INVENTORYROW_REQUEST: 'CREATE_INVENTORYROW_REQUEST',
    CREATE_INVENTORYROW_SUCCESS: 'CREATE_INVENTORYROW_SUCCESS',
    CREATE_INVENTORYROW_FAILURE: 'CREATE_INVENTORYROW_FAILURE',
    
    GET_INVENTORYROW_REQUEST: 'GET_INVENTORYROW_REQUEST',
    GET_INVENTORYROW_SUCCESS: 'GET_INVENTORYROW_SUCCESS',
    GET_INVENTORYROW_FAILURE: 'GET_INVENTORYROW_FAILURE',
    
    GETBYID_INVENTORYROW_REQUEST: 'GETBYID_INVENTORYROW_REQUEST',
    GETBYID_INVENTORYROW_SUCCESS: 'GETBYID_INVENTORYROW_SUCCESS',
    GETBYID_INVENTORYROW_FAILURE: 'GETBYID_INVENTORYROW_FAILURE',
    
    UPDATE_INVENTORYROW_REQUEST: 'UPDATE_INVENTORYROW_REQUEST',
    UPDATE_INVENTORYROW_SUCCESS: 'UPDATE_INVENTORYROW_SUCCESS',
    UPDATE_INVENTORYROW_FAILURE: 'UPDATE_INVENTORYROW_FAILURE',
    
    REMOVE_INVENTORYROW_REQUEST: 'REMOVE_INVENTORYROW_REQUEST',
    REMOVE_INVENTORYROW_SUCCESS: 'REMOVE_INVENTORYROW_SUCCESS',
    REMOVE_INVENTORYROW_FAILURE: 'REMOVE_INVENTORYROW_FAILURE'

};

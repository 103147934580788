export const alertrowConstants = {
    CREATE_ALERTROW_REQUEST: 'CREATE_ALERTROW_REQUEST',
    CREATE_ALERTROW_SUCCESS: 'CREATE_ALERTROW_SUCCESS',
    CREATE_ALERTROW_FAILURE: 'CREATE_ALERTROW_FAILURE',

    GET_ALERTROW_REQUEST: 'GET_ALERTROW_REQUEST',
    GET_ALERTROW_SUCCESS: 'GET_ALERTROW_SUCCESS',
    GET_ALERTROW_FAILURE: 'GET_ALERTROW_FAILURE',
    
    GET_ALERTROWFORDISTRIBUTIONPOINT_REQUEST: 'GET_ALERTROWFORDISTRIBUTIONPOINT_REQUEST',
    GET_ALERTROWFORDISTRIBUTIONPOINT_SUCCESS: 'GET_ALERTROWFORDISTRIBUTIONPOINT_SUCCESS',
    GET_ALERTROWFORDISTRIBUTIONPOINT_FAILURE: 'GET_ALERTROWFORDISTRIBUTIONPOINT_FAILURE',

    GET_LATEST_ALERTROWFORDISTRIBUTIONPOINT_REQUEST: 'GET_LATEST_ALERTROWFORDISTRIBUTIONPOINT_REQUEST',
    GET_LATEST_ALERTROWFORDISTRIBUTIONPOINT_SUCCESS: 'GET_LATEST_ALERTROWFORDISTRIBUTIONPOINT_SUCCESS',
    GET_LATEST_ALERTROWFORDISTRIBUTIONPOINT_FAILURE: 'GET_LATEST_ALERTROWFORDISTRIBUTIONPOINT_FAILURE',

    
    GETBYID_ALERTROW_REQUEST: 'GETBYID_ALERTROW_REQUEST',
    GETBYID_ALERTROW_SUCCESS: 'GETBYID_ALERTROW_SUCCESS',
    GETBYID_ALERTROW_FAILURE: 'GETBYID_ALERTROW_FAILURE',
    
    UPDATE_ALERTROW_REQUEST: 'UPDATE_ALERTROW_REQUEST',
    UPDATE_ALERTROW_SUCCESS: 'UPDATE_ALERTROW_SUCCESS',
    UPDATE_ALERTROW_FAILURE: 'UPDATE_ALERTROW_FAILURE',
    
    REMOVE_ALERTROW_REQUEST: 'REMOVE_ALERTROW_REQUEST',
    REMOVE_ALERTROW_SUCCESS: 'REMOVE_ALERTROW_SUCCESS',
    REMOVE_ALERTROW_FAILURE: 'REMOVE_ALERTROW_FAILURE'

};


export default class ProductRow {
    constructor(id = 0, created, updated, epc = '', productcode = '', productname = '', productsize = '', productcolor = '',distributionpointnumber = 0, 
              productrowidentifier = '', editedbyapplicationuserid = '00000000-0000-0000-0000-000000000000', editedbyapplicationusername = '') {
        this.Id = id;
        this.Created = created;
        this.Updated = updated;
        this.Epc = epc;
        this.ProductCode = productcode;
        this.ProductName = productname;
        this.ProductSize = productsize;
        this.ProductColor = productcolor;
        this.DistributionPointNumber = distributionpointnumber;   
        this.ProductRowIdentifier = productrowidentifier;
        this.EditedByApplicationUserId = editedbyapplicationuserid;
        this.EditedByApplicationUserName = editedbyapplicationusername;
      }    
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import "semantic-ui-css/components/button.min.css";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import "semantic-ui-css/components/form.min.css";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import "semantic-ui-css/components/segment.min.css";
import { loginActions } from "../actions/login.actions";
import resources from "../resources";
import "../styles/Login.css";
import configuration from "../configuration";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { username, password } = this.state;
    const { dispatch, navigate } = this.props;
    if (username && password) {
      dispatch(loginActions.login(username, password, navigate));
    }
  }

  render() {
    const { username, password } = this.state;
    const { loggingIn } = this.props;
    return (
      <Form size="large" onSubmit={this.handleSubmit}>
        <Segment stacked>
          <Form.Input
            fluid
            icon="user"
            iconPosition="left"
            placeholder={resources.USERNAME}
            name="username"
            value={username}
            required
            onChange={this.handleChange}
          />
          <Form.Input
            fluid
            icon="lock"
            iconPosition="left"
            placeholder={resources.PASSWORD}
            type="password"
            name="password"
            value={password}
            required
            onChange={this.handleChange}
          />
          <Button
            type="submit"
            color="google plus"
            fluid
            size="large"
            loading={loggingIn}
          >
            {resources.LOGIN}
          </Button>
          <div>
            <br />
            {configuration.APP_DESCRIPTION +
              " [ v" +
              configuration.APP_VERSION +
              " ]"}
            <br />
            {configuration.APP_AUTHOR}
            <br />
          </div>
        </Segment>
      </Form>
    );
  }
}

LoginForm.propTypes = {
  loggingIn: PropTypes.bool,
  history: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { loggingIn } = state.login;

  return {
    loggingIn,
    history: ownProps.history,
    navigate: ownProps.navigate,
  };
}

export default connect(mapStateToProps)(LoginForm);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import PropTypes from 'prop-types';
import BaseTable from '../components/BaseTable.component';
import resources from '../resources';
import { logActions } from '../actions/log.actions';
import moment from 'moment';
import { loglevelConstants } from '../constants/loglevel.constants';
import LogForm from './LogForm';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';

class LogTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            logDate: '',
            sortcolumn: '',
            sortorder: ''
        }
        this.loadData = this.loadData.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.sortby = this.sortby.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    deleteOldLogs(beforeDate){
        if(beforeDate !== '')
          this.props.dispatch(logActions.removeOldLogs(beforeDate));
    }
    sortby(col, order) {
        this.setState((prevState, props) => {
            return { sortcolumn: col, sortorder: order }
        }, () => {
            this.loadData();
        });
    }
    constructHeaders() {
        return [
            { value: resources.CREATED, name: 'Created' },
            { value: resources.TITLE, name: 'Title' },
            { value: resources.MESSAGE, name: 'Message' },
            { value: resources.LOGLEVEL, name: 'LogLevel' },
            { value: resources.USER, name: 'ApplicationUserId' },
            { value: resources.CLIENT_ID, name: 'ClientId' },
            { value: resources.EVENT, name: 'EventId' },
            { value: resources.SOURCE, name: 'Source' },
            { width: 1, value: '' }
        ];
    }

    constructRows(logs) {
        return logs.map((log, index) => {

            return {
                cells: [{
                    value: moment(log.Created).toISOString()
                }, {
                    value: (log.Title.length > 40 ? log.Title.substring(0, 40) : log.Title),
                    style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }
                }, {
                    value: (log.Message.length > 40 ? log.Message.substring(0, 40) : log.Message),
                    style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }
                }, {
                    value: log.LogLevel + ' ' + (log.LogLevel === loglevelConstants.Info ? resources.INFO :
                        (log.LogLevel === loglevelConstants.Warning ? resources.WARNING :
                            (log.LogLevel === loglevelConstants.Error ? resources.ERROR : '')))
                }, {
                    value: log.ApplicationUserId === '00000000-0000-0000-0000-000000000000' ? '' : log.ApplicationUserId
                }, {
                    value: log.ClientId
                }, {
                    value: log.EventId
                }, {
                    value: log.Source
                },
                {
                    custom: <LogForm editId={log.Id} size='mini' text='' />,
                    width:1,
                    style: { whiteSpace: 'nowrap', width:'10px' }

                }]
            };

        });
    }
    handleSearchChange = (e, { value }) => {

        this.setState({ search: value }, () => {

            if (this.searchTimeout && this.searchTimeout !== null) {
                clearTimeout(this.searchTimeout);
                this.searchTimeout = null;
            }
            this.searchTimeout = setTimeout(() => {

                this.loadData();

            }, 500);
        });
    }
    loadData(start = 0, rows = 15) {
        let { search, sortcolumn, sortorder } = this.state;
        this.props.dispatch(logActions.query(start, rows, search, sortcolumn, sortorder));
    }
    render() {
        const { logs, loadingLogs } = this.props;
        const { logDate } = this.state;
        return (
            <div>
                <Menu secondary stackable>
                    <Menu.Menu position='left'>
                        <Menu.Item>
                        <Input type='date' action
                            value={logDate}
                            onChange={(e) => this.setState({ 'logDate': e.target.value })}>
                            <input />
                            <Button disabled={logDate === ''} color='google plus' style={{ whiteSpace: 'nowrap' }} onClick={() => this.deleteOldLogs(logDate)}><Icon name='trash' /> {resources.DELETE_OLD}</Button>
                        </Input>
                           
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Input icon='search' placeholder={resources.SEARCH + '...'} onChange={this.handleSearchChange} />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <BaseTable
                    loading={loadingLogs}
                    headers={this.constructHeaders()}
                    rows={this.constructRows(logs)}
                    loadEntries={this.loadData}
                    sortby={this.sortby}
                
                />
            </div>
        )
    }
}

LogTable.propTypes = {
    logs: PropTypes.array.isRequired,
    loadingLogs: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
    const { logs, loadingLogs } = state.log;
    return { logs, loadingLogs };
}

export default connect(mapStateToProps)(LogTable);

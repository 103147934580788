import { productrowConstants } from '../constants/productrow.constants';
import ProductRow from '../models/ProductRow.model';

const initialState = {
  loadingProductRow: false,
  loadingProductRows: false,
  error: '',
  productrows: [],
  productrow: new ProductRow()
};


export function productrow(state = initialState, action) {
  switch (action.type) {

    // === Create ===

    case productrowConstants.CREATE_PRODUCTROW_REQUEST:
      return {
        ...state,
        loadingProductRow: true
      };
    case productrowConstants.CREATE_PRODUCTROW_SUCCESS:

      return {
        ...state, 
        loadingProductRow: false,
        productrow: { ...action.productrow },
        productrows: [action.productrow, ...state.productrows]
      };
    case productrowConstants.CREATE_PRODUCTROW_FAILURE:
      return {
        ...state,
        loadingProductRow: false,
        error: action.error
      };

    // === Get ===

    case productrowConstants.GET_PRODUCTROW_REQUEST:
      return {
        ...state,
        loadingProductRows: true
      };
    case productrowConstants.GET_PRODUCTROW_SUCCESS:
      return {
        ...state,
        loadingProductRows: false,
        productrows: [...action.productrows]
      };
    case productrowConstants.GET_PRODUCTROW_FAILURE:
      return {
        ...state,
        loadingProductRows: false,
        error: action.error
      };

    // === Get by epc ===

    case productrowConstants.GETBYEPC_PRODUCTROW_REQUEST:
      return {
        ...state,
        loadingProductRow: true
      }
    case productrowConstants.GETBYEPC_PRODUCTROW_SUCCESS:
      return {
        ...state,
        loadingProductRow: false,
        productrow: { ...action.productrow }
      }
    case productrowConstants.GETBYEPC_PRODUCTROW_FAILURE:
      return {
        ...state,
        loadingProductRow: false,
        error: action.error
      }

    // === Update ===

    case productrowConstants.UPDATE_PRODUCTROW_REQUEST:
      return {
        ...state,
        loadingProductRow: true
      };
    case productrowConstants.UPDATE_PRODUCTROW_SUCCESS:
      return {
        ...state,
        loadingProductRow: false,
        productrows: state.productrows.map(item => {
                                        if (item.Epc === action.productrow.Epc)
                                          return { ...action.productrow };
                                        else
                                          return item;
                                      }),
        productrow: { ...action.productrow }
      };
    case productrowConstants.UPDATE_PRODUCTROW_FAILURE:
      return {
        ...state,
        loadingProductRow: false,
        error: action.error
      };

    // === Remove ===

    case productrowConstants.REMOVE_PRODUCTROW_REQUEST:
      return {
        ...state,
        loadingProductRows:true
      }
    case productrowConstants.REMOVE_PRODUCTROW_SUCCESS:
      return {
        ...state,
        loadingProductRows:false,
        productrows: state.productrows.filter(item => item.Epc !== action.Epc)
      }
    case productrowConstants.REMOVE_PRODUCTROW_FAILURE:
      return {
        ...state, 
        loadingProductRows:false,
        error: action.error
      };

    default:
      return state;
  }
}
import {logConstants}  from '../constants/log.constants';
import {alertActions} from './alert.actions';
import resources from '../resources';
import LogService from '../services/log.service';
 
export const logActions = {  
    get,
    query,
    getById,
    removeOldLogs
};


/**
 * Get all objects array action
 */
function get() {
    return dispatch => {
        dispatch(request());

        new LogService().get()
                        .then(
                            logs => dispatch(success(logs)),
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.GET_FAILED + ': ' +error));
                            }
                        );

                        

    };

    function request() { return { type: logConstants.GET_LOG_REQUEST } }
    function success(logs) { return { type: logConstants.GET_LOG_SUCCESS, logs } }
    function failure(error) { return { type: logConstants.GET_LOG_FAILURE, error } }
}
/**
 * Query object array action
 * @param {*} start 
 * @param {*} rows 
 * @param {*} search 
 * @param {*} sortcolumn 
 * @param {*} sortorder 
 */
function query(start, rows, search, sortcolumn, sortorder) {
    return dispatch => {
        dispatch(request());

        new LogService().query(start,rows,search,sortcolumn,sortorder)
                        .then(
                            logs => dispatch(success(logs)),
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.GET_FAILED + ': ' +error));
                            }
                        );
    };

    function request() { return { type: logConstants.QUERY_LOG_REQUEST } }
    function success(logs) { return { type: logConstants.QUERY_LOG_SUCCESS, logs } }
    function failure(error) { return { type: logConstants.QUERY_LOG_FAILURE, error } }
}

/**
 * Query object array action
 * @param {*} id
 */
function getById(id) {
    return dispatch => {
        dispatch(request(id));

        new LogService().getById(id)
                        .then(
                            log => dispatch(success(log)),
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.GET_FAILED + ': ' + error));
                            }
                        );
    };

    function request(id) { return { type: logConstants.GETBYID_LOG_REQUEST, id} }
    function success(log) { return { type: logConstants.GETBYID_LOG_SUCCESS, log } }
    function failure(error) { return { type: logConstants.GETBYID_LOG_FAILURE, error } }
}
/**
 * Delete all before this date
 * @param {*} beforeDate
 */
function removeOldLogs(beforeDate) {
    return dispatch => {
        dispatch(request());

        new LogService().removeOldLogs(beforeDate)
                        .then(
                            response => {
                                dispatch(success(response));
                                dispatch(alertActions.success(resources.DELETE_SUCCESS));
                            },
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.DELETE_FAILED + ': ' + error));
                            }
                        );
    };

    function request() { return { type: logConstants.REMOVE_OLD_LOGS_REQUEST, beforeDate } }
    function success() { return { type: logConstants.REMOVE_OLD_LOGS_SUCCESS, beforeDate } }
    function failure(error) { return { type: logConstants.REMOVE_OLD_LOGS_FAILURE, error } }
}

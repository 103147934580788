import { authHeader } from "../helpers/auth.header";
import resources from "../resources";
import fetch from "cross-fetch";
import history from "../helpers/history.helper";

export function request(method, body, url) {
  let requestOptions = {
    method: method,
    headers: { "Content-Type": "application/json", ...authHeader() },
  };

  if (body !== null) {
    requestOptions = { ...requestOptions, body: body };
  }

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((response) => {
      return response;
    });
}

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then((json) => resolve(json));
      } else {
        resolve();
      }
    } else if (response.status === 401) {
      //Move to login, every time 401 'Unauthorized' status is received in any request
      if (!history.location.pathname.includes("kirjaudu")) {
        history.go("");
      } else {
        reject(resources.LOGIN_FAILED);
      }
    } else {
      // return error message from response body
      response.text().then((text) => reject(text));
    }
  });
}

function handleError(error) {
  return Promise.reject(error && error.message);
}

import { loglevelConstants } from "../constants/loglevel.constants";

export default class Log {
    constructor(id = 0, created, eventid = '', loglevel = loglevelConstants.Info, title = '', message = '', applicationuserid = '00000000-0000-0000-0000-000000000000', clientid = '', source = '') {
        this.Id = id;
        this.Created = created;
        this.EventId = eventid;   
        this.LogLevel = loglevel;
        this.Title = title;
        this.Message = message;
        this.ApplicationUserId = applicationuserid;
        this.ClientId = clientid;
        this.Source = source;


      }    
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import resources from "../resources";
import "../styles/LogTable.css";
import Dimmer from "semantic-ui-react/dist/commonjs/modules/Dimmer";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import { logActions } from "../actions/log.actions";
import Log from "../models/Log.model";
import LogSummary from "../components/LogSummary.component";

class LogForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showmodal: false,
      log: new Log(),
    };

    this.show = this.show.bind(this);
    this.close = this.close.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newLog = nextProps.log || {};

    if (prevState.log.Id !== newLog.Id) {
      //Change state based on props
      return { log: newLog };
    }

    //No changes
    return null;
  }
  show(editId) {
    this.setState({ log: new Log(), showmodal: true }, () => {
      if (editId) {
        this.props.dispatch(logActions.getById(editId));
      }
    });
  }
  close() {
    this.setState({ log: new Log(), showmodal: false });
  }

  render() {
    const { editId, size, text, loadingLog } = this.props;
    let { log, showmodal } = this.state;

    return (
      <div>
        {editId && (
          <Button
            icon
            size={size ? size : "mini"}
            onClick={() => this.show(editId)}
            primary
          >
            <Icon name="edit" />
            {text !== undefined ? text : resources.EDIT}
          </Button>
        )}

        <Modal size="large" open={showmodal} onClose={this.close} closeIcon>
          <Modal.Header>{resources.LOG}</Modal.Header>
          <Modal.Content style={{ padding: "0" }}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Segment basic>
                <Dimmer active={loadingLog} inverted>
                  <Loader />
                </Dimmer>

                <LogSummary log={log} />
              </Segment>
              <Segment
                attached="bottom"
                secondary
                style={{ margin: "0", width: "100%" }}
              >
                <Button onClick={() => this.close()}>{resources.CLOSE}</Button>
              </Segment>
            </Form>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

LogForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  log: PropTypes.object.isRequired,
  loadingLog: PropTypes.bool.isRequired,
  editId: PropTypes.number,
  size: PropTypes.string,
  text: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  const { log, loadingLog } = state.log;
  return {
    log,
    loadingLog,
    history: ownProps.history,
  };
}

export default connect(mapStateToProps)(LogForm);

import configuration from './configuration';

const resources = {
    'fi-FI' : {
        'NO_MATCH':'Valitettavasti etsimääsi sivua ei ole olemassa',
        'USERNAME':'Käyttäjänimi',
        'PASSWORD':'Salasana',
        'LOGIN_TITLE':'Sisäänkirjautuminen',
        'LOGIN':'Kirjaudu',
        'STORAGE_BALANCE':'Saldot',
        'MENU':'Valikko',
        'LOGOUT':'Ulos',
        'ALERT_AMOUNTS':'Hälytysrajat',
        'ORDER_LISTS':'Tilauslista',
        'MAINTENANCE':'Ylläpito',
        'LOGS':'Lokit',
        'LOG':'Loki',
        'LOGIN_FAILED':'Sisäänkirjautuminen epäonnistui',
        'ALERT_TITLE':'Oho!',
        'CURRENT_STATE':'Tilanne',
        'PRODUCT_IMPORT':'Tuotteiden tuonti',
        'GET_FAILED':'Haku epäonnistui',
        'SAVE_FAILED':'Tallennus epäonnistui',
        'SAVE_SUCCESS': 'Tallennettu',
        'DELETE_SUCCESS':'Poistettu',
        'DELETE_FAILED': 'Poisto epäonnistui',
        'SEND':'Lähetä',
        'PRODUCTS':'Tuotteita',
        'SENDING_PRODUCTS':'Lähetetään tuotteita',
        'SENDING':'Lähetetään',
        'SELECT_PRODUCT_CSV':'Valitse tuoterivit sisältävä csv -tiedosto',
        'PARSING_PRODUCT_FILE':'Poimitaan tuotteita csv -tiedostosta',
        'PICKED':'Poimittu',
        'ERRORS':'Virheitä',
        'NO_ROWSDATA':'Ei yhtään riviä?',
        'DATA_IN_WRONG_FORMAT':'Väärä muoto',
        'DATA_INVALID_NUMBER_OF_COLUMS': 'Virheellinen määrä sarakkeita',
        'DATA_INVALID_ALL_EPC_CODES_NOT_UNIQUE':'Epc koodien täytyy olla yksilöllisiä, joillakin riveillä on sama epc',
        'STRUCTURE_ERROR':'Virhe rakenteessa',
        'ROWS':'riviä',
        'ROW':'rivi',
        'FILE_MUST_BE_CSV':'Tiedoston pitää olla .csv -tiedosto',
        'PARSE_PRODUCTS_FROM_FILE':'Poimitaan tuotteet tiedostosta',
        'CHOOSE_FILE':'Valitse tiedosto',
        'CSV_CONTAINING_PRODUCTS':'.csv -tiedosto',
        'SELECT_SEND':'Klikkaa "Lähetä"',
        'NUMBR':'Nro',
        'CODE':'Koodi',
        'DESCRIPTION':'Kuvaus',
        'UPDATED':'Muokattu',
        'CREATE_NEW':'Luo uusi',
        'EDIT':'Muokkaa',
        'CANCEL':'Peruuta',
        'SAVE':'Tallenna',
        'NAME':'Nimi',
        'NO_ROWS':'Ei rivejä',
        'CREATE_NEW_DISTRIBUTIONPOINT':'Luo uusi jakelupiste',
        'ARE_YOU_SURE':'Oletko varma?',
        'DISTRIBUTION_NUMBR':'Jakelupisteen nro',
        'LATEST_TIMESTAMP': 'Viimeisin',
        'SIZE_PRODUCTCODE': 'Tuotenro',
        'PRODUCT_DESCRIPTION': 'Tuotteen kuvaus',
        'AMOUNT_IN_SHELF': 'Saldo',
        'GOALAMOUNT': 'Tavoite',
        'ORDERLIMIT': 'Tilausraja',
        'ALERTLIMIT': 'Hälyraja',
        'SEARCH':'Hae',
        'CLOSE':'Sulje',
        'INFO':'Info',
        'WARNING':'Varoitus',
        'ERROR':'Virhe',
        'DELETE_OLD':'Poista vanhat',
        'LOGLEVEL':'Lokitaso',
        'MESSAGE':'Viesti',
        'EVENT':'Tapahtuma',
        'SOURCE':'Lähde',
        'TITLE':'Otsikko',
        'USER':'Käyttäjä',
        'CLIENT_ID':'Client tunnus',
        'CREATED':'Luotu',
        'HISTORY':'Historia',
        'EXPORT_TO_CSV':'Vie csv',
        'SELECT_DISTRIBUTIONPOINT':'Valitse jakelupiste',
        'ORDERAMOUNT':'Tilausmäärä',
        'CREATE_NEW_ORDERLIST':'Luo uusi tilauslista',
        'CREATE_BY_DISTRIBUTIONPOINT_INFO':'Tilauslistan luonti on asetettu työjonoon ja valmistuu tuota pikaa',
        'PRINT':'Tulosta',
        'DISTRIBUTIONPOINT':'Jakelupiste',
        'CANNOT_EDIT_FOR_THIS_DISTRIBUTIONPOINT':'Et voi muokata tälle jakelupisteelle',
        'UNSAVED_CHANGES_WILL_BE_DISCARDED':'Tallentamattomat muutokset menetetään',
        'FRIDAY_GOALAMOUNT':'Perjantaitavoite',
        'DELIVERED':'Toimitettu',
        'EXTRA_INFO':'Lisätiedot',
        'REPORTS':'Raportit'
    },
    'en-US':{
        'NO_MATCH': 'The page you are looking for does not exist',
        'USERNAME':'Username',
        'PASSWORD':'Password',
        'LOGIN_TITLE':'User login',
        'LOGIN':'Login',
        'STORAGE_BALANCE':'Saldot',
        'MENU':'Valikko',
        'LOGOUT':'Sign out',
        'ALERT_AMOUNTS':'Alertamounts',
        'ORDER_LISTS':'Orderlist',
        'MAINTENANCE':'Maintenance',
        'LOGS':'Logs',
        'LOG':'Log',
        'LOGIN_FAILED':'Login failed',
        'ALERT_TITLE':'Wow!',
        'CURRENT_STATE':'State',
        'PRODUCT_IMPORT':'Product import',
        'GET_FAILED':'Get failed',
        'SAVE_FAILED':'Save failed',
        'SAVE_SUCCESS': 'Saved',
        'DELETE_SUCCESS':'Deleted',
        'DELETE_FAILED': 'Delete failed',
        'SEND':'Send',
        'PRODUCTS':'Products',
        'SENDING_PRODUCTS':'Sending products',
        'SENDING':'Sending',
        'SELECT_PRODUCT_CSV':'Select csv -file containing productrows',
        'PARSING_PRODUCT_FILE':'Picking products from csv -file',
        'PICKED':'Picked',
        'ERRORS':'Errors',
        'NO_ROWSDATA':'No rows?',
        'DATA_IN_WRONG_FORMAT':'Wrong format',
        'DATA_INVALID_NUMBER_OF_COLUMS': 'Invalid amount of columns',
        'DATA_INVALID_ALL_EPC_CODES_NOT_UNIQUE':'Epc codes must be unique, some rows have same epc',
        'STRUCTURE_ERROR':'Error on structure',
        'ROWS':'rows',
        'ROW':'row',
        'FILE_MUST_BE_CSV':'File should be .csv -file',
        'PARSE_PRODUCTS_FROM_FILE':'Pick products from file',
        'CHOOSE_FILE':'Choose file',
        'CSV_CONTAINING_PRODUCTS':'.csv -file',
        'SELECT_SEND':'Click "Send"',
        'NUMBR':'Nbr',
        'CODE':'Code',
        'DESCRIPTION':'Description',
        'UPDATED':'Updated',
        'CREATE_NEW':'Create new',
        'EDIT':'Edit',
        'CANCEL':'Cancel',
        'SAVE':'Save',
        'NAME':'Name',
        'NO_ROWS':'No rows',
        'CREATE_NEW_DISTRIBUTIONPOINT':'Create new distributionpoint',
        'ARE_YOU_SURE':'Are you sure',
        'DISTRIBUTION_NUMBR':'Distributionpoint nmbr',
        'LATEST_TIMESTAMP': 'Latest timestamp',
        'SIZE_PRODUCTCODE': 'Size productnumber',
        'PRODUCT_DESCRIPTION': 'Product describtion',
        'AMOUNT_IN_SHELF': 'Amount in shelf',
        'GOALAMOUNT': 'Goalamount',
        'ORDERLIMIT': 'Orderlimit',
        'ALERTLIMIT': 'Alertlimit',
        'SEARCH':'Search',
        'CLOSE':'Close',
        'INFO':'Info',
        'WARNING':'Varoitus',
        'ERROR':'Error',
        'DELETE_OLD':'Delete old',
        'LOGLEVEL':'Loglevel',
        'MESSAGE':'Message',
        'EVENT':'Event',
        'SOURCE':'Source',
        'TITLE':'Title',
        'USER':'User',
        'CLIENT_ID':'Client id',
        'CREATED':'Created',
        'HISTORY':'History',
        'EXPORT_TO_CSV':'Export to csv',
        'SELECT_DISTRIBUTIONPOINT':'Select point',
        'ORDERAMOUNT':'Orderamount',
        'CREATE_NEW_ORDERLIST':'Create new orderlist',
        'CREATE_BY_DISTRIBUTIONPOINT_INFO':'Creation of orderlist is scheduled and will be ready in just a minute',
        'PRINT':'Print',
        'DISTRIBUTIONPOINT':'Distributionpoint',
        'CANNOT_EDIT_FOR_THIS_DISTRIBUTIONPOINT':'Cannot edit for this distributionpoint',
        'UNSAVED_CHANGES_WILL_BE_DISCARDED':'Unsaved changes will be discarded',
        'FRIDAY_GOALAMOUNT':'Friday goal',
        'DELIVERED':'Delivered',
        'EXTRA_INFO':'Additional info',
        'REPORTS':'Raportit'
    }
};

/* if freeze is supported */
if(typeof Object !== "undefined" && !!Object.freeze){
    Object.freeze(resources);
}

export default resources[configuration.lang];
import Grud from './grud.service';
import { grudService } from './base.grud.service';
import { request } from './base.service';
class OrderRowService extends Grud {
  constructor() {
    super('orderrow');
  }
  /**
   * Query with distributionPoint
   */
  getByDistributionPoint(distributionPointNumber, start, rows, search, sortcolumn, sortorder) {

    let url = this.apiUrl;

    if (url.endsWith('/') === false)
      url += '/';

    url += 'distributionpoint/';

    if (distributionPointNumber)
      url += `${encodeURIComponent(distributionPointNumber)}`;
    else
      url += '-';

    return grudService.query(url, start, rows, search, sortcolumn, sortorder);

  }
  /**
   * Query all latest
   */
  queryLatest(distributionPointNumber, start, rows, search, sortcolumn, sortorder) {
    let url = this.apiUrl;

    if (url.endsWith('/') === false)
      url += '/';

    url += 'latest/distributionpoint/';

    if (distributionPointNumber)
      url += `${encodeURIComponent(distributionPointNumber)}`;
    else
      url += '-';

    let queryUrl = grudService.constructQueryUrl(url, start, rows, search, sortcolumn, sortorder);



    return request('GET', null, queryUrl)
      .then(response => {
        return response;
      });
  }
  /** 
   * Create orderlist be distributionPoint
   */
  createByDistributionPoint(distributionpointNumber){
      let url = this.apiUrl;
      if(url.endsWith('/') === false)
        url += '/';
      url += 'distributionpoint/' + distributionpointNumber + '/';
      return grudService.create({}, url);
    
  }

}
export default OrderRowService;
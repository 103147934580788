export const productrowConstants = {
    CREATE_PRODUCTROW_REQUEST: 'CREATE_PRODUCTROW_REQUEST',
    CREATE_PRODUCTROW_SUCCESS: 'CREATE_PRODUCTROW_SUCCESS',
    CREATE_PRODUCTROW_FAILURE: 'CREATE_PRODUCTROW_FAILURE',
    
    GET_PRODUCTROW_REQUEST: 'GET_PRODUCTROW_REQUEST',
    GET_PRODUCTROW_SUCCESS: 'GET_PRODUCTROW_SUCCESS',
    GET_PRODUCTROW_FAILURE: 'GET_PRODUCTROW_FAILURE',
    
    GETBYEPC_PRODUCTROW_REQUEST: 'GETBYEPC_PRODUCTROW_REQUEST',
    GETBYEPC_PRODUCTROW_SUCCESS: 'GETBYEPC_PRODUCTROW_SUCCESS',
    GETBYEPC_PRODUCTROW_FAILURE: 'GETBYEPC_PRODUCTROW_FAILURE',
    
    UPDATE_PRODUCTROW_REQUEST: 'UPDATE_PRODUCTROW_REQUEST',
    UPDATE_PRODUCTROW_SUCCESS: 'UPDATE_PRODUCTROW_SUCCESS',
    UPDATE_PRODUCTROW_FAILURE: 'UPDATE_PRODUCTROW_FAILURE',
    
    REMOVE_PRODUCTROW_REQUEST: 'REMOVE_PRODUCTROW_REQUEST',
    REMOVE_PRODUCTROW_SUCCESS: 'REMOVE_PRODUCTROW_SUCCESS',
    REMOVE_PRODUCTROW_FAILURE: 'REMOVE_PRODUCTROW_FAILURE'

};

import { productConstants }  from '../constants/product.constants';
import { alertActions } from './alert.actions';
import resources from '../resources';
import ProductService from '../services/product.service';
 
export const productActions = {  
    create
};
/**
 * Create array action
 * @param {*} data 
 */
function create(data) {
    return dispatch => {
        dispatch(request());

        return new ProductService().create(data)
                        .then(
                            result => {
                                dispatch(success());
                                dispatch(alertActions.success(resources.SAVE_SUCCESS));
                                return result;
                            },
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.SAVE_FAILED + ': ' + error));
                                return error;
                            }
                        );
    };

    function request() { return { type: productConstants.CREATE_PRODUCT_REQUEST } }
    function success() { return { type: productConstants.CREATE_PRODUCT_SUCCESS } }
    function failure(error) { return { type: productConstants.CREATE_PRODUCT_FAILURE, error } }
}


export default class DistributionPoint {
    constructor(id = 0, created, updated, pointumber = 1, pointname = '', pointdescription = '', pointcode = '', editedbyapplicationuserid = '00000000-0000-0000-0000-000000000000', editedbyapplicationusername = '') {
        this.Id = id;
        this.Created = created;
        this.Updated = updated;
        this.PointNumber = pointumber;   
        this.PointName = pointname;
        this.PointDescription = pointdescription;
        this.PointCode = pointcode;
        this.EditedByApplicationUserId = editedbyapplicationuserid;
        this.EditedByApplicationUserName = editedbyapplicationusername;
      }    
}
import { productConstants } from '../constants/product.constants';


const initialState = {
  loadingProducts: false,
  error: '',
  product: {
    Epc: '',
    ProductCode: '',
    ProductName: '',
    ProductSize: '',
    ProductColor: '',
    DistributionPointNumber: 0,
    ProductRowIdentifier: '',
    EditedByApplicationUserId: '',
    EditedByApplicationUserName: '',
    Id: 0,
    Created: '',
    Updated: ''
  }
};


export function product(state = initialState, action) {
  switch (action.type) {

    // === Create ===

    case productConstants.CREATE_PRODUCT_REQUEST:
      return {
        ...state,
        loadingProducts: true
      };
    case productConstants.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingProducts: false
      };
    case productConstants.CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        loadingProducts: false,
        error: action.error
      };


    default:
      return state;
  }
}
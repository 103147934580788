import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'semantic-ui-react/dist/commonjs/collections/Table';
import 'semantic-ui-css/components/table.min.css';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import 'semantic-ui-css/components/segment.min.css';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';
import 'semantic-ui-css/components/dimmer.min.css';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import 'semantic-ui-css/components/loader.min.css';


import BasePaging from './BasePaging.component';
import resources from '../resources';

class BaseTable extends Component {
    constructor(props){
        super(props);
        this.componentKey = this.props.parentkey === undefined ? 'basetable' : this.props.parentkey;
        this.state = {
            [this.componentKey + 'lastInnerWidth'] : window.innerWidth, 
            [this.componentKey + 'mobileBreakpoint']: 768, 
            [this.componentKey + 'laptopBreakpoint']: 1024,
            [this.componentKey + 'sortcolumn']:'',
            [this.componentKey + 'sortorder']:''
        };
        this.resize = this.resize.bind(this);
        this.sortby = this.sortby.bind(this);
    }
    
  
    componentDidMount(){
        window.addEventListener("resize",this.resize);
    }
    componentWillUnmount(){
        window.removeEventListener("resize", this.resize);
    }
    resize(ev){
        
        //If current width is over laptop, and last width was not
        if(window.innerWidth >= this.state[this.componentKey + 'laptopBreakpoint'] && this.state[this.componentKey + 'lastInnerWidth'] <= this.state[this.componentKey + 'laptopBreakpoint']){

            this.setState({[this.componentKey + 'lastInnerWidth']:window.innerWidth});
            return;
        }

        //If current width is over mobile, and last width was not
        if(window.innerWidth >= this.state[this.componentKey + 'mobileBreakpoint'] && this.state[this.componentKey + 'lastInnerWidth'] <= this.state[this.componentKey + 'mobileBreakpoint']){
  
            this.setState({[this.componentKey + 'lastInnerWidth']:window.innerWidth});
            return;
        }
        //If current width is smaller than laptop, and last width was not
        if(window.innerWidth <= this.state[this.componentKey + 'laptopBreakpoint'] && this.state[this.componentKey + 'lastInnerWidth'] >= this.state[this.componentKey + 'laptopBreakpoint']){
    
            this.setState({[this.componentKey + 'lastInnerWidth']:window.innerWidth});
            return;
        }
        //If current width is smaller than mobile, and last width wass not
        if(window.innerWidth <= this.state[this.componentKey + 'mobileBreakpoint'] && this.state[this.componentKey + 'lastInnerWidth'] >= this.state[this.componentKey + 'mobileBreakpoint']){
 
            this.setState({[this.componentKey + 'lastInnerWidth']:window.innerWidth});
            return;
        }

    }
    sortby(name){
        this.setState((prevState, props) => {
            return { [this.componentKey + 'sortcolumn'] : name, [this.componentKey + 'sortorder'] : prevState[this.componentKey + 'sortcolumn'] !== name ? 'ascending' : prevState[this.componentKey + 'sortorder'] === 'descending' ? 'ascending' : 'descending'} 
        }, () => {
            if(this.props.sortby)
                this.props.sortby(this.state[this.componentKey + 'sortcolumn'], this.state[this.componentKey + 'sortorder'] === 'ascending' ? 'asc' : 'desc');
        });
    }
    render() {
        

        let tableColumnMaxCount = this.props.headers.length;
        const {mobilecolumns,laptopcolumns} = this.props;
        if(window.innerWidth <= this.state[this.componentKey + 'mobileBreakpoint'])
            tableColumnMaxCount = Math.min(mobilecolumns || 2,this.props.headers.length);
        else if(window.innerWidth <= this.state[this.componentKey + 'laptopBreakpoint'])
            tableColumnMaxCount = Math.min(laptopcolumns || 5,this.props.headers.length);

        let viewHeaders = [...this.props.headers.filter((value,index) => { return index < tableColumnMaxCount || index === this.props.headers.length-1; })];
        let viewRows = [...this.props.rows].map((value,index) => {
            if(value.cells)
                value = {...value, ...{cells:[...value.cells.filter((cell,cellindex) => { return cellindex < tableColumnMaxCount || cellindex === this.props.headers.length-1; })] }};
            else 
                value = {...value};
            return value;
        });
        const { parentkey } = this.props;
    
        let componentKey = parentkey === undefined ? 'basetable' : parentkey;

        let unstack = this.props.unstackable ? this.props.unstackable : true;
        return (
            <Segment basic style={{ padding: '0' }}>
                <Dimmer active={this.props.loading} inverted>
                    <Loader />
                </Dimmer>

                <Table className={this.props.className} celled={this.props.celled !== undefined ? this.props.celled : true} striped={this.props.striped !== undefined ? this.props.striped : true} 
                        sortable={this.props.sortby ? true : false} compact unstackable={unstack} basic={this.props.basic} color={this.props.color}
                        key={componentKey + '-table'}>
                    <Table.Header>
                        <Table.Row>

                            {viewHeaders.map((header, index) => {

                             
                                return <Table.HeaderCell width={header.width} style={header.style}
                                                        key={componentKey + '-userheadercell_' + index} 
                                                        onClick={() => { if(header.name) this.sortby(header.name)} }
                                                        sorted={this.state[this.componentKey + 'sortcolumn'] === header.name ? this.state[this.componentKey + 'sortorder'] : undefined}>
                                            {header.value}
                                        </Table.HeaderCell>;
                            })}


                        </Table.Row>
                    </Table.Header>

                    <Table.Body>

                        {viewRows.map((row, index) => {
                            return <Table.Row key={componentKey + '-userrow_' + index}>
                                {row.cells.map((cell, cellindex) => {

                                    let cellContent = <span>{cell.value}</span>;

                                    if (cell.custom) {
                                        return <Table.Cell key={componentKey + '-userrow_' + index + '_cell_' + cellindex} style={cell.style} warning={cell.warning} negative={cell.negative} positive={cell.positive}>{cell.custom}</Table.Cell>;
                                    }
                                    else if (cell.link) {
                                        cellContent = <Link key={componentKey + '-userrow_cellink'} to={cell.link}>{cell.value}</Link>;
                                    }
                                    if (cell.fixed) {
                                        cellContent = <div key={componentKey + '-userrow_' + index + '_cell_' + cellindex + '_content'} style={{ width: cell.width, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                            {cellContent}
                                        </div>;
                                    }
                                   
                                    return <Table.Cell key={componentKey + '-userrow_' + index + '_cell_' + cellindex} style={cell.style} warning={cell.warning} negative={cell.negative} positive={cell.positive}>
                                        {cellContent}
                                    </Table.Cell>;
                                })}
                            </Table.Row>;
                        })}
                        {this.props.rows.length === 0 &&
                            <Table.Row>
                                <Table.Cell key={componentKey + '-tablecell'} colSpan={this.props.headers.length}>{resources.NO_ROWS}</Table.Cell>
                            </Table.Row>}

                    </Table.Body>

                    {this.props.loadEntries &&
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan={this.props.headers.length} textAlign='center'>
                                    <BasePaging 
                                        key={componentKey + '-tablepaging'}
                                        parentkey={componentKey + '-tablepaging'}
                                        loadEntries={this.props.loadEntries}
                                        rows={this.props.pageSize} />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    }
                </Table>
            </Segment>
        )
    }
}


BaseTable.propTypes = {
    headers: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loadEntries: PropTypes.func,
    pageSize: PropTypes.number,
    sortby: PropTypes.func,
    sortable: PropTypes.bool,
    color: PropTypes.string,
    parentkey: PropTypes.string,
    className: PropTypes.string,
    mobilecolumns: PropTypes.number,
    laptopcolumns: PropTypes.number
}
export default BaseTable;
export const orderrowConstants = {
    CREATE_ORDERROW_REQUEST: 'CREATE_ORDERROW_REQUEST',
    CREATE_ORDERROW_SUCCESS: 'CREATE_ORDERROW_SUCCESS',
    CREATE_ORDERROW_FAILURE: 'CREATE_ORDERROW_FAILURE',
    
    GET_ORDERROW_REQUEST: 'GET_ORDERROW_REQUEST',
    GET_ORDERROW_SUCCESS: 'GET_ORDERROW_SUCCESS',
    GET_ORDERROW_FAILURE: 'GET_ORDERROW_FAILURE',
    
    GET_ORDERROWFORDISTRIBUTIONPOINT_REQUEST: 'GET_ORDERROWFORDISTRIBUTIONPOINT_REQUEST',
    GET_ORDERROWFORDISTRIBUTIONPOINT_SUCCESS: 'GET_ORDERROWFORDISTRIBUTIONPOINT_SUCCESS',
    GET_ORDERROWFORDISTRIBUTIONPOINT_FAILURE: 'GET_ORDERROWFORDISTRIBUTIONPOINT_FAILURE',

    GET_LATEST_ORDERROWFORDISTRIBUTIONPOINT_REQUEST: 'GET_LATEST_ORDERROWFORDISTRIBUTIONPOINT_REQUEST',
    GET_LATEST_ORDERROWFORDISTRIBUTIONPOINT_SUCCESS: 'GET_LATEST_ORDERROWFORDISTRIBUTIONPOINT_SUCCESS',
    GET_LATEST_ORDERROWFORDISTRIBUTIONPOINT_FAILURE: 'GET_LATEST_ORDERROWFORDISTRIBUTIONPOINT_FAILURE',

    GETBYID_ORDERROW_REQUEST: 'GETBYID_ORDERROW_REQUEST',
    GETBYID_ORDERROW_SUCCESS: 'GETBYID_ORDERROW_SUCCESS',
    GETBYID_ORDERROW_FAILURE: 'GETBYID_ORDERROW_FAILURE',
    
    UPDATE_ORDERROW_REQUEST: 'UPDATE_ORDERROW_REQUEST',
    UPDATE_ORDERROW_SUCCESS: 'UPDATE_ORDERROW_SUCCESS',
    UPDATE_ORDERROW_FAILURE: 'UPDATE_ORDERROW_FAILURE',
    
    REMOVE_ORDERROW_REQUEST: 'REMOVE_ORDERROW_REQUEST',
    REMOVE_ORDERROW_SUCCESS: 'REMOVE_ORDERROW_SUCCESS',
    REMOVE_ORDERROW_FAILURE: 'REMOVE_ORDERROW_FAILURE',

    CREATE_FORDISTRIBUTIONPOINT_REQUEST:'CREATE_FORDISTRIBUTIONPOINT_REQUEST',
    CREATE_FORDISTRIBUTIONPOINT_SUCCESS:'CREATE_FORDISTRIBUTIONPOINT_SUCCESS',
    CREATE_FORDISTRIBUTIONPOINT_FAILURE:'CREATE_FORDISTRIBUTIONPOINT_FAILURE'

};

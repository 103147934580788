import React, { Component } from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import resources from "../resources";
import "../styles/Login.css";
import logo from "../img/logo.svg";
import LoginForm from "../containers/LoginForm.container";
import { useNavigate } from "react-router-dom";

const Login = ({ history }) => {
  const navigate = useNavigate();
  let innerHeight = window.innerHeight;
  return (
    <div className="login-form" style={{ height: innerHeight + "px" }}>
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Image src={logo} centered />
          <Header as="h2" textAlign="center">
            {" " + resources.LOGIN_TITLE}
          </Header>
          <LoginForm history={history} navigate={navigate} />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Login;

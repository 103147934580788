import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import resources from "../resources";
import "../styles/AlertRowTable.css";
import BaseTable from "../components/BaseTable.component";
import moment from "moment";
import { alertrowActions } from "../actions/alertrow.actions";
import { CSVLink } from "react-csv/lib";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import { distributionpointActions } from "../actions/distributionpoint.actions";
import { int32 } from "../constants/int.constants";

class AlertRowTable extends Component {
  constructor(props) {
    super(props);
    this.csvDownloadRef = React.createRef();
    this.state = {
      search: "",
      sortcolumn: "",
      sortorder: "",
      latest: false,
      distributionpoint: 0,
      loadingCsv: false,
      allLoaded: null,
    };
    this.loadData = this.loadData.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.sortby = this.sortby.bind(this);
    this.handleDistributionPointChange =
      this.handleDistributionPointChange.bind(this);
    this.loadAll = this.loadAll.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.latest !== nextProps.latest) {
      //Change state based on props
      return { latest: nextProps.latest };
    }

    //No changes
    return null;
  }
  componentDidMount() {
    this.loadData();
    this.loadDistributionPoints();
  }
  sortby(col, order) {
    this.setState({ sortcolumn: col, sortorder: order }, () => {
      this.loadData();
    });
  }
  constructHeaders() {
    return [
      { value: resources.DISTRIBUTION_NUMBR, name: "DistributionPointNumber" },
      { value: resources.LATEST_TIMESTAMP, name: "Updated" },
      { value: resources.SIZE_PRODUCTCODE, name: "ProductCode" },
      { value: resources.PRODUCT_DESCRIPTION, name: "ProductName" },
      { value: resources.AMOUNT_IN_SHELF, width: 1, name: "Amount" },
      { value: resources.GOALAMOUNT, width: 1, name: "GoalAmount" },
      { value: resources.ORDERLIMIT, width: 1, name: "OrderLimit" },
      { value: resources.ALERTLIMIT, width: 1, name: "AlertLimit" },
    ];
  }

  constructRows(alertrows) {
    return alertrows.map((alertrow, index) => {
      let amount = alertrow.Amount;
      let orderlimit = alertrow.OrderLimit;
      let alertlimit = alertrow.AlertLimit;

      return {
        cells: [
          {
            value:
              alertrow.DistributionPointNumber +
              " - " +
              alertrow.DistributionPointName,
          },
          {
            value: moment(alertrow.Updated).format("D.M.YYYY HH:mm"),
          },
          {
            value: alertrow.ProductCode,
          },
          {
            value:
              alertrow.ProductName +
              //  (alertrow.ProductColor ? ", " + alertrow.ProductColor : '') +
              (alertrow.ProductSize ? ", " + alertrow.ProductSize : ""),
            style: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px",
            },
          },
          {
            negative: amount < alertlimit,
            value: alertrow.Amount.toFixed(2).replace(".", ","),
          },
          {
            value: alertrow.GoalAmount.toFixed(2).replace(".", ","),
          },
          {
            warning: amount < orderlimit,
            value: alertrow.OrderLimit.toFixed(2).replace(".", ","),
          },
          {
            negative: amount < alertlimit,
            value: alertrow.AlertLimit.toFixed(2).replace(".", ","),
          },
        ],
      };
    });
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ search: value }, () => {
      if (this.searchTimeout && this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      this.searchTimeout = setTimeout(() => {
        this.loadData();
      }, 500);
    });
  };
  loadAll() {
    let { search, sortcolumn, sortorder, latest, distributionpoint } =
      this.state;

    this.setState({ loadingCsv: true }, () => {
      alertrowActions
        .getDataByDistributionPoint(
          latest,
          distributionpoint === 0 ? undefined : distributionpoint,
          search,
          sortcolumn,
          sortorder
        )
        .then((response) => {
          this.setState({ allLoaded: response }, () => {
            if (
              this.csvDownloadRef &&
              this.csvDownloadRef.current &&
              this.csvDownloadRef.current.link
            )
              this.csvDownloadRef.current.link.click();

            this.setState({ allLoaded: null, loadingCsv: false });
          });
        });
    });
  }
  loadData(start = 0, rows) {
    let { search, sortcolumn, sortorder, latest, distributionpoint } =
      this.state;
    const { pageSize } = this.props;

    if (pageSize && rows === undefined) rows = pageSize;
    else if (rows === undefined) rows = 15;

    if (latest)
      this.props.dispatch(
        alertrowActions.getLatestByDistributionPoint(
          distributionpoint === 0 ? undefined : distributionpoint,
          start,
          rows,
          search,
          sortcolumn,
          sortorder
        )
      );
    else
      this.props.dispatch(
        alertrowActions.getByDistributionPoint(
          distributionpoint === 0 ? undefined : distributionpoint,
          start,
          rows,
          search,
          sortcolumn,
          sortorder
        )
      );
  }
  loadDistributionPoints() {
    this.props.dispatch(distributionpointActions.get());
  }
  getCsv(alertrows) {
    let head = this.constructHeaders();

    let result = [head.map((i) => i.value)];

    let rows = this.constructRows(alertrows);

    rows.forEach((r) => {
      result.push(r.cells.map((i) => ("csv" in i ? i.csv : i.value)));
    });

    return result;
  }

  handleDistributionPointChange(e, obj) {
    if (this.state.distributionpoint !== obj.value) {
      this.setState({ distributionpoint: obj.value }, () => {
        if (this.distributionTimeout && this.distributionTimeout !== null) {
          clearTimeout(this.distributionTimeout);
          this.distributionTimeout = null;
        }

        this.distributionTimeout = setTimeout(() => {
          this.loadData();
        }, 500);
      });
    }
  }
  getPointOptions(distributionpoints) {
    let points = [{ key: "projectstate_e", text: "", value: "" }];

    points = [
      ...points,
      ...distributionpoints.map((item, index) => {
        return {
          key: "orderrow-point_" + index,
          text: item.PointNumber + " - " + item.PointName,
          value: item.PointNumber,
        };
      }),
    ];

    return points;
  }
  openPrint() {
    window.print();
  }
  render() {
    const {
      alertrows,
      latestalertrows,
      loadingAlertRows,
      distributionpoints,
      loadingDistributionPoints,
      pageSize,
      key,
    } = this.props;
    let { latest, distributionpoint, allLoaded, loadingCsv } = this.state;

    const alertrowsData = latest ? latestalertrows : alertrows;
    const csvData = pageSize === int32.MAX ? alertrowsData : allLoaded;

    let componentKey = key === undefined ? "AlertRowtable" : key;
    let point = distributionpoints.find(
      (i) => i.PointNumber === distributionpoint
    );

    return (
      <div>
        <table className="ui table very basic show-on-print">
          <tbody>
            <tr>
              {distributionpoint !== 0 &&
                distributionpoint !== undefined &&
                distributionpoint !== "" &&
                distributionpoints.length > 0 && (
                  <td>
                    <strong>{resources.DISTRIBUTIONPOINT}</strong>
                    {": " + distributionpoint + " - " + point.PointName}
                  </td>
                )}
              {alertrowsData && alertrowsData.length > 0 && (
                <td>
                  <strong>{resources.LATEST_TIMESTAMP}</strong>
                  {": " +
                    moment(
                      alertrowsData.reduce((prev, current) => {
                        return moment(prev.Created).isAfter(
                          moment(current.Created)
                        )
                          ? prev
                          : current;
                      }).Created
                    ).format("D.M.YYYY HH:mm")}
                </td>
              )}
            </tr>
          </tbody>
        </table>

        <Menu secondary stackable>
          <Menu.Menu position="left">
            <Menu.Item>
              <Dropdown
                key={"alertrow-distributionpointselect"}
                selection
                loading={loadingDistributionPoints || loadingAlertRows}
                options={this.getPointOptions(distributionpoints)}
                placeholder={resources.SELECT_DISTRIBUTIONPOINT}
                onChange={this.handleDistributionPointChange}
              />
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right">
            <Menu.Item>
              <Button
                icon
                basic
                size="mini"
                onClick={this.openPrint}
                disabled={
                  distributionpoint === 0 ||
                  distributionpoint === undefined ||
                  distributionpoint === ""
                }
              >
                <Icon name="print" /> {resources.PRINT}
              </Button>
            </Menu.Item>
            <Menu.Item>
              {csvData === null && (
                <Button
                  icon
                  basic
                  size="medium"
                  onClick={this.loadAll}
                  loading={loadingCsv}
                  disabled={loadingCsv}
                >
                  <Icon name="file excel outline" /> {resources.EXPORT_TO_CSV}
                </Button>
              )}
              {csvData !== null && (
                <CSVLink
                  ref={this.csvDownloadRef}
                  filename={resources.ALERT_AMOUNTS + ".csv"}
                  data={this.getCsv(csvData)}
                  separator={";"}
                >
                  <Button icon basic size="medium">
                    <Icon name="file excel outline" /> {resources.EXPORT_TO_CSV}
                  </Button>
                </CSVLink>
              )}
            </Menu.Item>
            <Menu.Item>
              <Input
                icon="search"
                placeholder={resources.SEARCH + "..."}
                onChange={this.handleSearchChange}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <BaseTable
          key={"alertrowtable-" + componentKey}
          parentkey={"alertrowtable-" + componentKey}
          className={"alertrowtable-print"}
          loading={loadingAlertRows}
          headers={this.constructHeaders()}
          rows={this.constructRows(alertrowsData)}
          sortby={this.sortby}
          pageSize={pageSize || 15}
          loadEntries={latest ? undefined : this.loadData}
        />
      </div>
    );
  }
}

AlertRowTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loadingAlertRows: PropTypes.bool.isRequired,
  alertrows: PropTypes.array,
  latestalertrows: PropTypes.array,
  latest: PropTypes.bool,
  pageSize: PropTypes.number,
  key: PropTypes.string,
  distributionpoints: PropTypes.array,
  loadingDistributionPoints: PropTypes.bool,
};

function mapStateToProps(state) {
  const { distributionpoints, loadingDistributionPoints } =
    state.distributionpoint;
  const { alertrows, latestalertrows, loadingAlertRows } = state.alertrow;
  return {
    alertrows,
    latestalertrows,
    loadingAlertRows,
    distributionpoints,
    loadingDistributionPoints,
  };
}

export default connect(mapStateToProps)(AlertRowTable);

import React, { Component } from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Tab from "semantic-ui-react/dist/commonjs/modules/Tab";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import resources from "../resources";
import MainMenu from "../components/MainMenu.component";
import "../styles/AlertAmounts.css";
import AlertRowTable from "../containers/AlertRowTable.container";
import { int32 } from "../constants/int.constants";

class AlertAmounts extends Component {
  render() {
    return (
      <div>
        <MainMenu
          history={this.props.history}
          location={this.props.location}
          user={this.props.user}
          navigate={this.props.navigate}
        />

        <Container fluid className="main">
          <Grid columns={1} relaxed padded stackable>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3" color="red" className="finnid-header">
                  {resources.ALERT_AMOUNTS}
                </Header>

                <Tab
                  attached="top"
                  panes={[
                    {
                      menuItem: resources.CURRENT_STATE,
                      render: () => (
                        <Segment attached="bottom">
                          <AlertRowTable
                            latest={true}
                            pageSize={int32.MAX}
                            parentkey="latest_alertrows"
                            key="latest_alertrows"
                          />
                        </Segment>
                      ),
                    },
                    {
                      menuItem: resources.HISTORY,
                      render: () => (
                        <Segment attached="bottom">
                          <AlertRowTable
                            latest={false}
                            pageSize={27}
                            parentkey="history_alertrows"
                            key="history_alertrows"
                          />
                        </Segment>
                      ),
                    },
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default AlertAmounts;

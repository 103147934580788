import React, { Component } from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import MainMenu from "../components/MainMenu.component";
import "../styles/OrderList.css";
import * as signalR from "@microsoft/signalr";
import configuration from "../configuration";
import { authToken } from "../helpers/auth.header";
import moment from "moment";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
} from "semantic-ui-react";

class TagReads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rfidLogs: [
        {
          msg: "Odotetaan...",
          timestamp: moment().format("D.M.YYYY HH:mm:ss.SSSS"),
        },
      ],
      tagReads: { "Odotetaan lukijatietoja": [] },
    };
  }
  componentDidMount() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(configuration.ROOT_URL + "/eventhub", {
        accessTokenFactory: () => authToken(),
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    //Add header to connection

    this.connection.on("TagReads", (tagReads) => {
      this.setTagReads(tagReads);
    });
    this.connection.on("RfidLog", (log, timestamp) => {
      console.log(log, timestamp);
      this.setState((currState) => {
        return {
          rfidLogs: [...currState.rfidLogs, { msg: log, timestamp: timestamp }],
        };
      });
    });
    this.connection
      .start()
      .then(() => {
        console.log("connected");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  componentWillUnmount() {
    this.connection.stop();
  }
  //Create function to convert TagReads to array of objects for every reader hostname with list of epc codes and timestamps
  setTagReads = (tagReads) => {
    let tagReadsByReader = {};
    for (let key in tagReads) {
      const hostname = key.split("_")[0];
      const epc = key.split("_")[1];
      const timestamp = tagReads[key];
      if (!tagReadsByReader[hostname]) tagReadsByReader[hostname] = [];

      tagReadsByReader[hostname].push({ epc, timestamp });
    }
    console.log(tagReadsByReader);
    this.setState({ tagReads: tagReadsByReader });
  };
  render() {
    return (
      <div>
        <MainMenu
          history={this.props.history}
          location={this.props.location}
          user={this.props.user}
          navigate={this.props.navigate}
        />

        <Container fluid className="main">
          <Grid columns={1} relaxed padded stackable>
            <Grid.Row>
              <Grid.Column>
                <Header
                  as="h3"
                  color="red"
                  className="finnid-header orderlist-header"
                >
                  REAALIAIKANÄKYMÄ: RFID LUENNAT (MQTT)
                </Header>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "500px", paddingRight: "1em" }}>
                    {this.state.rfidLogs.map((log, i) => (
                      <div key={`rfid-log-${i}-${log.timestamp}`}>
                        <strong>
                          {moment(log.timestamp).format(
                            "D.M.YYYY HH:mm:ss.SSSS"
                          )}
                        </strong>
                        <br />
                        {log.msg}
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      flex: "auto",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      rowGap: "1em",
                      columnGap: "1em",
                    }}
                  >
                    {Object.keys(this.state.tagReads).map((hostname) => {
                      //Loop through reads of each reader
                      const reads = this.state.tagReads[hostname];

                      return (
                        <div>
                          <Table
                            selectable
                            striped
                            key={`tagreads-${hostname}`}
                          >
                            <TableHeader>
                              <TableRow>
                                <TableHeaderCell colSpan="2">
                                  Lukija: {hostname}
                                </TableHeaderCell>
                              </TableRow>
                              <TableRow>
                                <TableHeaderCell>EPC</TableHeaderCell>
                                <TableHeaderCell>Aikaleima</TableHeaderCell>
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {reads.map((read) => (
                                <TableRow>
                                  <TableCell>{read.epc}</TableCell>
                                  <TableCell>
                                    {moment(read.timestamp).format(
                                      "D.M.YYYY HH:mm:ss.SSSS"
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default TagReads;

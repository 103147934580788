const configuration = {
  //App language
  lang: process.env.REACT_APP_LANG,
  AUTH_URL: process.env.REACT_APP_AUTH_URL,
  ROOT_URL: process.env.REACT_APP_ROOT_URL,
  TOKEN_LOCALSTORAGE_KEY: "attune-token",
  APP_VERSION: process.env.REACT_APP_VERSION || "",
  APP_DESCRIPTION: process.env.REACT_APP_DESCRIPTION || "",
  APP_AUTHOR: process.env.REACT_APP_AUTHOR || "",
  keyCodes: {
    Esc: 27,
    KeyOne: 49,
    KeyTwo: 50,
    KeyThree: 51,
    Keyfour: 52,
    Down: 40,
    Up: 38,
    Left: 37,
    Right: 39,
    BackSpace: 8,
    Enter: 13,
    Tab: 9,
    Barcode: 1000,
    Space: 32,
  },
  //https://github.com/lindell/JsBarcode#options
  barcodeoptions: {
    format: "CODE39",
    font: "Lato",
    fontSize: 10,
    height: 35,
    textAlign: "right",
  },
};

/* if freeze is supported */
if (typeof Object !== "undefined" && !!Object.freeze) {
  Object.freeze(configuration);
}

export default configuration;

import "babel-polyfill";
import "semantic-ui-css/semantic.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import Root from "./pages/Root";
import configureStore from "./store/configure.store";
import "./styles/main.css";
import "./styles/print.css";
const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root store={store} />);

export const productpointlimitConstants = {
    CREATE_PRODUCTPOINTLIMIT_REQUEST: 'CREATE_PRODUCTPOINTLIMIT_REQUEST',
    CREATE_PRODUCTPOINTLIMIT_SUCCESS: 'CREATE_PRODUCTPOINTLIMIT_SUCCESS',
    CREATE_PRODUCTPOINTLIMIT_FAILURE: 'CREATE_PRODUCTPOINTLIMIT_FAILURE',

    
    ADDORUPDATE_PRODUCTPOINTLIMITS_REQUEST: 'ADDORUPDATE_PRODUCTPOINTLIMITS_REQUEST',
    ADDORUPDATE_PRODUCTPOINTLIMITS_SUCCESS: 'ADDORUPDATE_PRODUCTPOINTLIMITS_SUCCESS',
    ADDORUPDATE_PRODUCTPOINTLIMITS_FAILURE: 'ADDORUPDATE_PRODUCTPOINTLIMITS_FAILURE',
    
    GET_PRODUCTPOINTLIMIT_REQUEST: 'GET_PRODUCTPOINTLIMIT_REQUEST',
    GET_PRODUCTPOINTLIMIT_SUCCESS: 'GET_PRODUCTPOINTLIMIT_SUCCESS',
    GET_PRODUCTPOINTLIMIT_FAILURE: 'GET_PRODUCTPOINTLIMIT_FAILURE',
    
    GETBYID_PRODUCTPOINTLIMIT_REQUEST: 'GETBYID_PRODUCTPOINTLIMIT_REQUEST',
    GETBYID_PRODUCTPOINTLIMIT_SUCCESS: 'GETBYID_PRODUCTPOINTLIMIT_SUCCESS',
    GETBYID_PRODUCTPOINTLIMIT_FAILURE: 'GETBYID_PRODUCTPOINTLIMIT_FAILURE',
    
    UPDATE_PRODUCTPOINTLIMIT_REQUEST: 'UPDATE_PRODUCTPOINTLIMIT_REQUEST',
    UPDATE_PRODUCTPOINTLIMIT_SUCCESS: 'UPDATE_PRODUCTPOINTLIMIT_SUCCESS',
    UPDATE_PRODUCTPOINTLIMIT_FAILURE: 'UPDATE_PRODUCTPOINTLIMIT_FAILURE',
    
    REMOVE_PRODUCTPOINTLIMIT_REQUEST: 'REMOVE_PRODUCTPOINTLIMIT_REQUEST',
    REMOVE_PRODUCTPOINTLIMIT_SUCCESS: 'REMOVE_PRODUCTPOINTLIMIT_SUCCESS',
    REMOVE_PRODUCTPOINTLIMIT_FAILURE: 'REMOVE_PRODUCTPOINTLIMIT_FAILURE'

};

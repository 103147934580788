import React, { Component } from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import resources from "../resources";
import MainMenu from "../components/MainMenu.component";
import "../styles/Logs.css";
import LogsTable from "../containers/LogsTable.container";

class Logs extends Component {
  render() {
    return (
      <div>
        <MainMenu
          history={this.props.history}
          location={this.props.location}
          user={this.props.user}
          navigate={this.props.navigate}
        />

        <Container fluid className="main">
          <Grid columns={1} relaxed padded stackable>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3" color="red" className="finnid-header">
                  {resources.LOGS}
                </Header>

                <LogsTable />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default Logs;

import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import configuration from "../configuration";
import moment from "moment";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();
  let userTokenItem = localStorage.getItem(
    configuration.TOKEN_LOCALSTORAGE_KEY
  );
  let userToken;
  let allowed = true;
  if (userTokenItem) {
    userToken = JSON.parse(userTokenItem);

    if (roles) {
      allowed =
        roles.findIndex((role) => userToken.user.Roles.includes(role)) > -1;
    }
  }

  useEffect(() => {
    if (
      !userToken ||
      !moment(userToken.token.ValidTo).isAfter(moment()) ||
      !allowed
    ) {
      navigate("/kirjaudu");
    }
  }, []);

  if (userToken && moment(userToken.token.ValidTo).isAfter(moment()) && allowed)
    return (
      <Component
        {...rest}
        location={location}
        navigate={navigate}
        user={userToken.user}
      />
    );
  else {
    return null;
  }
};

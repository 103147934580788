import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Provider, connect } from "react-redux";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import history from "../helpers/history.helper";
import { PrivateRoute } from "../components/PrivateRoute.component";
import Login from "./Login";
import StorageBalance from "./StorageBalance";
import resources from "../resources";
import AlertAmounts from "./AlertAmounts";
import OrderLists from "./OrderLists";
import Maintenance from "./Maintenance";
import { alertActions } from "../actions/alert.actions";
import Logs from "./Logs";
import TransitionablePortal from "semantic-ui-react/dist/commonjs/addons/TransitionablePortal";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import "semantic-ui-css/components/message.min.css";
import TagReads from "./TagReads";

const Root = (props) => {
  const { dispatch, alert } = props;

  const handleClick = () => {
    dispatch(alertActions.clear());
  };

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setShowAlert(alert && "message" in alert);
  }, [alert]);

  const animation = "fly up";
  const duration = 500;
  const alertPop = (
    <TransitionablePortal
      open={alert && "message" in alert}
      transition={{ animation, duration }}
      onClose={handleClick}
    >
      <Message floating color={alert.color} className="alert-message">
        <Message.Header>{resources.ALERT_TITLE}</Message.Header>
        <p>{alert.message}</p>
      </Message>
    </TransitionablePortal>
  );

  return (
    <Provider store={props.store}>
      <div style={{ height: "inherit" }}>
        {showAlert && alertPop}

        <Router history={history}>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <PrivateRoute
                  roles={["SuperUser", "Admin", "Viewer"]}
                  component={StorageBalance}
                  history={history}
                />
              }
            />
            <Route
              exact
              path="/kirjaudu"
              element={<Login history={history} />}
            />
            <Route
              exact
              path="/hälytysrajat"
              element={
                <PrivateRoute
                  roles={["Admin", "SuperUser", "Viewer"]}
                  component={AlertAmounts}
                  history={history}
                />
              }
            />
            <Route
              exact
              path="/tilauslista"
              element={
                <PrivateRoute component={OrderLists} history={history} />
              }
            />
            <Route
              exact
              path="/ylläpito"
              element={
                <PrivateRoute
                  roles={["Admin", "SuperUser", "Viewer"]}
                  component={Maintenance}
                  history={history}
                />
              }
            />
            <Route
              exact
              path="/lokit"
              element={
                <PrivateRoute
                  roles={["Admin"]}
                  component={Logs}
                  history={history}
                />
              }
            />
            <Route
              exact
              path="/luennat"
              element={
                <PrivateRoute
                  roles={["Admin"]}
                  component={TagReads}
                  history={history}
                />
              }
            />

            <Route path="*" element={<h1>{resources.NO_MATCH}</h1>} />
          </Routes>
        </Router>
      </div>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}

export default connect(mapStateToProps)(Root);

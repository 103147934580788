import { productpointlimitConstants } from '../constants/productpointlimit.constants';
import ProductPointLimit from '../models/ProductPointLimit.model';

const initialState = {
  loadingProductPointLimit: false,
  loadingProductPointLimits: false,
  error: '',
  productpointlimits: [],
  productpointlimit: new ProductPointLimit()
};


export function productpointlimit(state = initialState, action) {
  switch (action.type) {

    // === Create ===

    case productpointlimitConstants.CREATE_PRODUCTPOINTLIMIT_REQUEST:
      return {
        ...state,
        loadingProductPointLimit: true
      };
    case productpointlimitConstants.CREATE_PRODUCTPOINTLIMIT_SUCCESS:
      return {
        ...state,
        loadingProductPointLimit: false,
        productpointlimit: { ...action.productpointlimit },
        productpointlimits: [action.productpointlimit, ...state.productpointlimits]
      };
    case productpointlimitConstants.CREATE_PRODUCTPOINTLIMIT_FAILURE:
      return {
        ...state,
        loadingProductPointLimit: false,
        error: action.error
      };

    // === Add or Update ===

    case productpointlimitConstants.ADDORUPDATE_PRODUCTPOINTLIMITS_REQUEST:
      return {
        ...state,
        loadingProductPointLimit: true
      };
    case productpointlimitConstants.ADDORUPDATE_PRODUCTPOINTLIMITS_SUCCESS:

      let removedOverlapping = [...state.productpointlimits].filter((item) => !action.productpointlimits.find((t) => t.Id === item.Id));

      return {
        ...state,
        loadingProductPointLimit: false,
        productpointlimits: [...removedOverlapping, action.productpointlimits]
      };
    case productpointlimitConstants.ADDORUPDATE_PRODUCTPOINTLIMITS_FAILURE:
      return {
        ...state,
        loadingProductPointLimit: false,
        error: action.error
      };



    // === Get ===

    case productpointlimitConstants.GET_PRODUCTPOINTLIMIT_REQUEST:
      return {
        ...state,
        loadingProductPointLimits: true
      };
    case productpointlimitConstants.GET_PRODUCTPOINTLIMIT_SUCCESS:
      return {
        ...state,
        loadingProductPointLimits: false,
        productpointlimits: [...action.productpointlimits]
      };
    case productpointlimitConstants.GET_PRODUCTPOINTLIMIT_FAILURE:
      return {
        ...state,
        loadingProductPointLimits: false,
        error: action.error
      };

    // === Get by ID ===

    case productpointlimitConstants.GETBYID_PRODUCTPOINTLIMIT_REQUEST:
      return {
        ...state,
        loadingProductPointLimit: true
      }
    case productpointlimitConstants.GETBYID_PRODUCTPOINTLIMIT_SUCCESS:
      return {
        ...state,
        loadingProductPointLimit: false,
        productpointlimit: { ...action.productpointlimit }
      }
    case productpointlimitConstants.GETBYID_PRODUCTPOINTLIMIT_FAILURE:
      return {
        ...state,
        loadingProductPointLimit: false,
        error: action.error
      }

    // === Update ===

    case productpointlimitConstants.UPDATE_PRODUCTPOINTLIMIT_REQUEST:
      return {
        ...state,
        loadingProductPointLimit: true
      };
    case productpointlimitConstants.UPDATE_PRODUCTPOINTLIMIT_SUCCESS:
      return {
        ...state,
        loadingProductPointLimit: false,
        productpointlimits: state.productpointlimits.map(item => {
          if (item.Id === action.productpointlimit.Id)
            return { ...action.productpointlimit };
          else
            return item;
        }),
        productpointlimit: { ...action.productpointlimit }
      };
    case productpointlimitConstants.UPDATE_PRODUCTPOINTLIMIT_FAILURE:
      return {
        ...state,
        loadingProductPointLimit: false,
        error: action.error
      };

    // === Remove ===

    case productpointlimitConstants.REMOVE_PRODUCTPOINTLIMIT_REQUEST:
      return {
        ...state,
        loadingProductPointLimits: true
      }
    case productpointlimitConstants.REMOVE_PRODUCTPOINTLIMIT_SUCCESS:
      return {
        ...state,
        loadingProductPointLimits: false,
        productpointlimits: state.productpointlimits.filter(item => item.Id !== action.id)
      }
    case productpointlimitConstants.REMOVE_PRODUCTPOINTLIMIT_FAILURE:
      return {
        ...state,
        loadingProductPointLimits: false,
        error: action.error
      };

    default:
      return state;
  }
}
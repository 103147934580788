import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Step from "semantic-ui-react/dist/commonjs/elements/Step";
import resources from "../resources";
import "../styles/DistributionPointTable.css";
import Papa from "papaparse/papaparse.min.js";
import { productActions } from "../actions/product.actions";
import ProductRow from "../models/ProductRow.model";
import moment from "moment";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";

class ProductImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: new Date(),
      data: [],
      parsing: false,
      parseError: "",
      parseStep: "",
      filename: "",
    };
    this.parse = this.parse.bind(this);
    this.completed = this.completed.bind(this);
    this.setError = this.setError.bind(this);
    this.setParseStep = this.setParseStep.bind(this);
  }
  parse(e) {
    if (
      e.target &&
      e.target.value &&
      e.target.files &&
      e.target.files.length > 0
    ) {
      let file = e.target.files[0];

      if (e.target.value.toLowerCase().endsWith("csv") === false) {
        this.setState({
          filename: "",
          data: [],
          parseError: resources.FILE_MUST_BE_CSV,
          parseStep: "",
        });
      } else {
        this.setState(
          {
            filename: e.target.value,
            data: [],
            start: new Date(),
            parsing: true,
            parseError: "",
            parseStep: "",
          },
          () => {
            //Use settimeout, so ui has some time to re render
            setTimeout(() => {
              Papa.parse(file, {
                header: false,
                download: true,
                skipEmptyLines: true,
                delimiter: ";",
                complete: this.completed,
                error: this.setError,
                // step: this.setParseStep,
                fastMode: true,
              });
            }, 10);
          }
        );
      }
    } else {
      this.setState({ filename: e.target.value });
    }
  }
  setParseStep(results, parser) {
    // console.log("setParseStep", results);
    /*
        //Must be setTimeout, for ui to update
        setTimeout(() => {
            this.setState((prevState, props) => {
                let newData = [...prevState.data, results.data[0]];
                return {
                    data: newData,
                    parseStep: resources.PICKED + ': ' + newData.length + ' ' + resources.ROWS + ' ' + moment(new Date() - prevState.start).format('mm:ss.SSS') + 's'
                };
            });
        }, 1);*/
  }
  setError(error, file) {
    //Must be setTimeout, for ui to update
    setTimeout(() => {
      this.setState((prevState, props) => {
        return {
          parseError:
            moment(new Date() - prevState.start).format("mm:ss.SSS") +
            " : " +
            resources.ERROR +
            ": " +
            error.toString() +
            "\n",
        };
      });
    }, 1);
  }
  completed(results, file) {
    let data = [...results.data];

    //Remove header
    data.splice(0, 1); //Remove header

    //Validate data
    let validateError = this.validate(data);

    if (validateError !== "") {
      this.setState((prevState, props) => {
        return {
          parsing: false,
          parseStep:
            resources.PICKED +
            ": " +
            data.length +
            " " +
            resources.ROWS +
            " " +
            moment(new Date() - prevState.start).format("mm:ss.SSS") +
            "s",
          parseError:
            prevState.parseError +
            (validateError !== ""
              ? resources.STRUCTURE_ERROR + ": " + validateError
              : ""),
        };
      });
    } else {
      //Convert to productrows
      let productRows = this.convertToProductRows(data);

      //Must be setTimeout, for ui to update
      // setTimeout(() => {
      this.setState(
        (prevState, props) => {
          return {
            parsing: false,
            data: productRows,
            parseStep:
              resources.PICKED +
              ": " +
              productRows.length +
              " " +
              resources.ROWS +
              " " +
              moment(new Date() - prevState.start).format("mm:ss.SSS") +
              "s",
            parseError:
              prevState.parseError +
              (validateError !== ""
                ? resources.STRUCTURE_ERROR + ": " + validateError
                : ""),
          };
        },
        () => {
          //Auto upload
          this.upload(this.state.data);
        }
      );
      // }, 10);
    }
  }
  /**
   * Data:
   *
   * KundenNr	MatchCode_Kunde	    Abteilung	ArtikelNr	MatchCode_Art	    Farbe	    Groesse	    Identnummer
   * 00100A	OYS osastot *	    1424	    50410	    Housut, mikrokuitu	SININEN	    2XL	        2831B38300EE80BF
   * 00100A	OYS osastot *	    1415	    50410	    Housut, mikrokuitu	SININEN	    2XL	        3350007FE23870D0
   * ...
   * @param {*} data
   */
  validate(data) {
    let validateError = "";

    if (data.length === 0) {
      validateError = resources.NO_ROWSDATA;
      return validateError;
    }

    if (typeof data !== "object") {
      validateError = resources.DATA_IN_WRONG_FORMAT;
      return validateError;
    }

    let epcCodes = [];

    for (let index = 0; index < data.length; index++) {
      let element = data[index];
      if (element.length < 8) {
        //Add 2 to index because excel starts from 1, and there is a headerrow
        validateError +=
          resources.ROW +
          "(" +
          (index + 2) +
          ") " +
          (validateError !== "" ? ", " : "") +
          resources.DATA_INVALID_NUMBER_OF_COLUMS +
          "\n";
        break;
      } else {
        //Last column is epc -code
        epcCodes.push(element[7]);
      }
    }

    if (validateError !== "") return validateError;

    let uniqueEpcCodes = [...new Set([...epcCodes])];

    if (epcCodes.length !== uniqueEpcCodes.length) {
      validateError +=
        (validateError !== "" ? ", " : "") +
        resources.DATA_INVALID_ALL_EPC_CODES_NOT_UNIQUE +
        "\n";
      return validateError;
    }

    return validateError;
  }
  /**
   * Convert from
   * KundenNr	MatchCode_Kunde	    Abteilung	ArtikelNr	MatchCode_Art	    Farbe	    Groesse	    Identnummer
   * 00100A	OYS osastot *	    1424	    50410	    Housut, mikrokuitu	SININEN	    2XL	        2831B38300EE80BF
   *
   * To productRow objects
   * @param {*} data
   */
  convertToProductRows(data) {
    return data.map((item) => {
      let row = new ProductRow();
      row.ProductCode = item[3];
      row.ProductName = item[4];
      row.ProductColor = item[5];
      row.ProductSize = item[6];
      row.Epc = item[7];
      return row;
    });
  }
  upload(data) {
    const { parseError, parsing } = this.state;
    if (data.length > 0 && parseError === "" && parsing === false) {
      this.setState(
        { parseStep: resources.PRODUCTS + " (" + data.length + ")" },
        () => {
          this.props.dispatch(productActions.create(data)).then((result) => {
            this.setState({
              start: new Date(),
              data: [],
              parsing: false,
              parseError: "",
              parseStep: "",
              filename: "",
            });
          });
        }
      );
    }
  }
  render() {
    const { loadingProducts } = this.props;
    const { parsing, parseStep, parseError, data, filename } = this.state;

    return (
      <div>
        <Step.Group ordered>
          <Step active={filename === ""} completed={filename !== ""}>
            <Step.Content>
              <Step.Title>{resources.CHOOSE_FILE}</Step.Title>
              <Step.Description>
                {resources.CSV_CONTAINING_PRODUCTS}
              </Step.Description>
            </Step.Content>
          </Step>

          <Step
            active={parsing === true}
            completed={
              filename !== "" &&
              parsing === false &&
              data.length > 0 &&
              parseError === ""
            }
          >
            <Step.Content>
              <Step.Title>
                {parsing && <Icon name="circle notched" loading />}
                {resources.PARSE_PRODUCTS_FROM_FILE}
              </Step.Title>
              {filename !== "" && (
                <Step.Description>
                  <strong>{resources.PARSING_PRODUCT_FILE}</strong> <br />
                  {parseStep}{" "}
                  {parseStep !== "" &&
                    (parseError === "" ? (
                      <Icon name="checkmark box" color="green" />
                    ) : (
                      <Icon name="remove circle" color="red" />
                    ))}
                </Step.Description>
              )}
            </Step.Content>
          </Step>

          <Step
            active={
              filename !== "" &&
              parsing === false &&
              data.length > 0 &&
              parseError === ""
            }
          >
            <Step.Content>
              <Step.Title>
                {loadingProducts && <Icon name="circle notched" loading />}
                {resources.SENDING}
              </Step.Title>
              <Step.Description>
                {loadingProducts && (
                  <strong>{resources.SENDING_PRODUCTS}..</strong>
                )}
              </Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
        <br />

        {parseError !== "" && (
          <div>
            <TextArea
              style={{ minHeight: "30px", width: "100%" }}
              value={parseError}
            ></TextArea>
            <br />
          </div>
        )}

        {loadingProducts === false && (
          <Input
            placeholder={resources.SELECT_PRODUCT_CSV}
            action
            loading={parsing}
          >
            <input type="file" onChange={(e) => this.parse(e)} />
            <Button type="button" icon basic>
              <Icon name="upload" />
            </Button>
          </Input>
        )}
      </div>
    );
  }
}

ProductImport.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loadingProducts: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

function mapStateToProps(state) {
  const { loadingProducts, error } = state.product;
  return {
    loadingProducts,
    error,
  };
}

export default connect(mapStateToProps)(ProductImport);

import React, { Component } from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import resources from "../resources";
import MainMenu from "../components/MainMenu.component";
import "../styles/StorageBalance.css";
import StorageBalanceTable from "../containers/StorageBalanceTable.container";

class StorageBalance extends Component {
  render() {
    return (
      <div>
        <MainMenu
          history={this.props.history}
          location={this.props.location}
          user={this.props.user}
          navigate={this.props.navigate}
        />

        <Container fluid className="main">
          <Grid columns={1} relaxed padded stackable>
            <Grid.Row>
              <Grid.Column>
                <Header
                  as="h3"
                  color="red"
                  className="finnid-header"
                  style={{ float: "left" }}
                >
                  {resources.STORAGE_BALANCE}
                </Header>

                <StorageBalanceTable user={this.props.user} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default StorageBalance;

import configuration from "../configuration";
import { grudService } from "./base.grud.service";

class Grud {
  constructor(api) {
    this.apiUrl = configuration.ROOT_URL + "/" + api + "/";
  }
  /**
   * Create object
   * @param {*} data
   * @param {*} url
   */
  create(data) {
    return grudService.create(data, this.apiUrl);
  }
  /**
   * Get any url
   */
  getByUrl(url) {
    return grudService.get(url);
  }
  /**
   * Get all
   */
  get() {
    return this.getByUrl(this.apiUrl);
  }
  /**
   * Query all
   */
  query(start, rows, search, sortcolumn, sortorder) {
    return grudService.query(
      this.apiUrl,
      start,
      rows,
      search,
      sortcolumn,
      sortorder
    );
  }
  /**
   * Query all by dates
   */
  querybydates(start, rows, startDate, endDate, search, sortcolumn, sortorder) {
    return grudService.query(
      this.apiUrl,
      start,
      rows,
      search,
      sortcolumn,
      sortorder,
      startDate,
      endDate
    );
  }
  /**
   * Get object by id
   * @param {*} id
   */
  getById(id) {
    return grudService.getById(id, this.apiUrl);
  }
  /**
   * Update object by id
   */
  update(id, data) {
    return grudService.update(id, data, this.apiUrl);
  }
  /**
   * Delte object by id
   */
  remove(id) {
    return grudService.remove(id, this.apiUrl);
  }
}
export default Grud;

import { logConstants } from '../constants/log.constants';
import moment from 'moment';
import Log from '../models/Log.model';

const initialState = {
  loadingLog:false,
  loadingLogs:false,
  error:'',
  logs:[],
  log: new Log()
};

 
export function log(state = initialState, action) {
  switch (action.type) {

      
    case logConstants.GET_LOG_REQUEST:
      return {
        ...state,
        loadingLogs:true
      };
    case logConstants.GET_LOG_SUCCESS:
      return {
        ...state,
        loadingLogs:false,
        logs:[...action.logs]
      };
    case logConstants.GET_LOG_FAILURE:
      return {
        ...state, 
        loadingLogs:false,
        error: action.error
      };

    case logConstants.GETBYID_LOG_REQUEST:
      return {
        ...state,
        loadingLog:true
      }
    case logConstants.GETBYID_LOG_SUCCESS:
      return {
        ...state,
        loadingLog:false,
        log:{...action.log}
      }
    case logConstants.GETBYID_LOG_FAILURE:
      return {
        ...state,
        loadingLog:false,
        error:action.error
      }

    case logConstants.QUERY_LOG_REQUEST:
      return {
        ...state,
        loadingLogs:true
      };
    case logConstants.QUERY_LOG_SUCCESS:
      return {
        ...state,
        loadingLogs:false,
        logs:[...action.logs],
      };
    case logConstants.QUERY_LOG_FAILURE:
      return {
        ...state, 
        loadingLogs:false,
        error: action.error
      };


    case logConstants.REMOVE_OLD_LOGS_REQUEST:
      return {
        ...state,
        loadingLogs:true
      }
    case logConstants.REMOVE_OLD_LOGS_SUCCESS:
      return {
        ...state,
        loadingLogs:false,
        logs: state.logs.filter(item => moment(item.Created).isAfter(action.beforeDate))
      }
    case logConstants.REMOVE_OLD_LOGS_FAILURE:
      return {
        ...state, 
        loadingLogs:false,
        error: action.error
      };

    

    default:
      return state;
  }
}
import Grud from './grud.service';
import {request} from './base.service';

class LogService extends Grud {
    constructor() {
      super('log');
    }
  
    /**
     * Delete older than
     */
    removeOldLogs(beforeDate){
        if(this.apiUrl.endsWith('/') === false)
            this.apiUrl += '/';
            
        return request('DELETE', null, this.apiUrl +  `?date=${encodeURIComponent(beforeDate)}`)
            .then(response => {
                return response;
            });
    }
}
export default LogService;
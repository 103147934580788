import { amountsinstorageConstants } from "../constants/amountsinstorage.constants";
import { alertActions } from "./alert.actions";
import resources from "../resources";
import AmountsInStorageService from "../services/amountsinstorage.service";

export const amountsinstorageActions = {
  get,
  getbyDistributionpoint,
  clear,
};

/**
 * Query object array action
 * @param {*} start
 * @param {*} rows
 * @param {*} search
 * @param {*} sortcolumn
 * @param {*} sortorder
 */
function get(start, rows, search, sortcolumn, sortorder) {
  return (dispatch) => {
    dispatch(request());

    return new AmountsInStorageService()
      .query(start, rows, search, sortcolumn, sortorder)
      .then(
        (amountsinstorages) => {
          dispatch(success(amountsinstorages));
          return amountsinstorages;
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(resources.GET_FAILED + ": " + error));
          return error;
        }
      );
  };

  function request() {
    return { type: amountsinstorageConstants.GET_AMOUNTSINSTORAGE_REQUEST };
  }
  function success(amountsinstorages) {
    return {
      type: amountsinstorageConstants.GET_AMOUNTSINSTORAGE_SUCCESS,
      amountsinstorages,
    };
  }
  function failure(error) {
    return {
      type: amountsinstorageConstants.GET_AMOUNTSINSTORAGE_FAILURE,
      error,
    };
  }
}

function clear() {
  return (dispatch) =>
    dispatch({ type: amountsinstorageConstants.CLEAR_AMOUNTSINSTORAGE });
}
/**
 * Query object array action
 * @param {*} start
 * @param {*} rows
 * @param {*} search
 * @param {*} sortcolumn
 * @param {*} sortorder
 */
function getbyDistributionpoint(
  distributionpoint,
  start,
  rows,
  search,
  sortcolumn,
  sortorder
) {
  return (dispatch) => {
    dispatch(request());

    return new AmountsInStorageService()
      .querybyDistributionpoint(
        distributionpoint,
        start,
        rows,
        search,
        sortcolumn,
        sortorder
      )
      .then(
        (amountsinstorages) => {
          dispatch(success(amountsinstorages));
          return amountsinstorages;
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(resources.GET_FAILED + ": " + error));
          return error;
        }
      );
  };

  function request() {
    return { type: amountsinstorageConstants.GET_AMOUNTSINSTORAGE_REQUEST };
  }
  function success(amountsinstorages) {
    return {
      type: amountsinstorageConstants.GET_AMOUNTSINSTORAGE_SUCCESS,
      amountsinstorages,
    };
  }
  function failure(error) {
    return {
      type: amountsinstorageConstants.GET_AMOUNTSINSTORAGE_FAILURE,
      error,
    };
  }
}

import { inventoryConstants } from '../constants/inventory.constants';

const initialState = {
  loadingInventory: false,
  error: '',
  inventory: {
    DistributionPointNumber: 0,
    InventoryRowIdentifier: '',
    Epc: '',
    ReadTime: '',
    Tid: '',
    EditedByApplicationUserId: '',
    EditedByApplicationUserName: '',
    Id: 0,
    Created: '',
    Updated: ''
  }
};


export function inventory(state = initialState, action) {
  switch (action.type) {

    // === Create ===

    case inventoryConstants.CREATE_INVENTORY_REQUEST:
      return {
        ...state,
        loadingInventory: true
      };
    case inventoryConstants.CREATE_INVENTORY_SUCCESS:
      return {
        ...state,
        loadingInventory: false
      };
    case inventoryConstants.CREATE_INVENTORY_FAILURE:
      return {
        ...state,
        loadingInventory: false,
        error: action.error
      };

    // === Create by distributionpoint ===

    case inventoryConstants.CREATE_INVENTORYFORDISTRIBUTIONPOINT_REQUEST:
      return {
        ...state,
        loadingInventory: true
      };
    case inventoryConstants.CREATE_INVENTORYFORDISTRIBUTIONPOINT_SUCCESS:
      return {
        ...state,
        loadingInventory: false
      };
    case inventoryConstants.CREATE_INVENTORYFORDISTRIBUTIONPOINT_FAILURE:
      return {
        ...state,
        loadingInventory: false,
        error: action.error
      };

    default:
      return state;
  }
}
import Grud from './grud.service';
import { grudService } from './base.grud.service';
import { request } from './base.service';

class AlertRowService extends Grud {
  constructor() {
    super('alertrow');
  }
  /**
 * Query with distributionPoint
 */
  getByDistributionPoint(distributionPointNumber, start, rows, search, sortcolumn, sortorder) {

    let url = this.apiUrl;

    if (url.endsWith('/') === false)
      url += '/';

    url += 'distributionpoint/';

    if (distributionPointNumber)
      url += `${encodeURIComponent(distributionPointNumber)}`;
    else
      url += '-';

    return grudService.query(url, start, rows, search, sortcolumn, sortorder);

  }
  /**
   * Query all latest
   */
  queryLatest(distributionPointNumber, start, rows, search, sortcolumn, sortorder) {
    let url = this.apiUrl;

    if (url.endsWith('/') === false)
      url += '/';

    url += 'latest/distributionpoint/';

    if (distributionPointNumber)
      url += `${encodeURIComponent(distributionPointNumber)}`;
    else
      url += '-';

    let queryUrl = grudService.constructQueryUrl(url, start, rows, search, sortcolumn, sortorder);



    return request('GET', null, queryUrl)
      .then(response => {
        return response;
      });
  }

}
export default AlertRowService;
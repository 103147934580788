export const inventoryConstants = {
    CREATE_INVENTORYFORDISTRIBUTIONPOINT_REQUEST: 'CREATE_INVENTORYFORDISTRIBUTIONPOINT_REQUEST',
    CREATE_INVENTORYFORDISTRIBUTIONPOINT_SUCCESS: 'CREATE_INVENTORYFORDISTRIBUTIONPOINT_SUCCESS',
    CREATE_INVENTORYFORDISTRIBUTIONPOINT_FAILURE: 'CREATE_INVENTORYFORDISTRIBUTIONPOINT_FAILURE',
    

    CREATE_INVENTORY_REQUEST: 'CREATE_INVENTORY_REQUEST',
    CREATE_INVENTORY_SUCCESS: 'CREATE_INVENTORY_SUCCESS',
    CREATE_INVENTORY_FAILURE: 'CREATE_INVENTORY_FAILURE'
};

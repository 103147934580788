import configuration from '../configuration';
import {request} from './base.service';
 
export const loginService = {
    login,
    logout
};
 
function login(username, password) {
  
 
    return request('POST', JSON.stringify({ username, password }), configuration.AUTH_URL)
        .then(response => {
            // login successful if there's a jwt token in the response
            if (response && response.token && response.token.Value) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(configuration.TOKEN_LOCALSTORAGE_KEY, JSON.stringify(response));
            }
 
            return response;
        });
}
 
function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(configuration.TOKEN_LOCALSTORAGE_KEY);
}
 
import { applyMiddleware, createStore } from "redux";
import { thunk } from "redux-thunk";
import { createLogger } from "redux-logger";
import reducer from "../reducers";

const middleware = [thunk];

//Add logger in developement
if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger());
}

export default function configureStore(preloadedState) {
  return createStore(reducer, preloadedState, applyMiddleware(...middleware));
}
// compose(applyMiddleware(...middlewares))

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal';
import resources from '../resources';
import '../styles/DistributionPointTable.css';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import { distributionpointActions } from '../actions/distributionpoint.actions';
import DistributionPoint from '../models/DistributionPoint.model';


class DistributionPointForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showmodal: false,
            distributionpoint: new DistributionPoint()
        };

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        this.remove = this.remove.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        let newDistributionpoint = nextProps.distributionpoint || {};

        if (prevState.distributionpoint.Id !== newDistributionpoint.Id) {
            //Change state based on props
            return { distributionpoint: newDistributionpoint };
        }

        //No changes
        return null;

    }
    show(editId) {
        this.setState({ distributionpoint: new DistributionPoint(), showmodal: true }, () => {
            if (editId) {
                this.props.dispatch(distributionpointActions.getById(editId));
            }
        });
    }
    close() {
        this.setState({ distributionpoint: new DistributionPoint(), showmodal: false });
    }
    onchange(key, value) {

        this.setState((prevState, props) => {
            return {
                distributionpoint: { ...prevState.distributionpoint, ...{ [key]: value } }
            };
        });
    }
    remove(editId) {
        if (editId) {
            if(window.confirm(resources.ARE_YOU_SURE)){
                this.props.dispatch(distributionpointActions.remove(editId))
                    .then(response => {
                        this.close();
                    });
            }
        }
    }
    save(editId) {
        if (editId) {
            this.props.dispatch(distributionpointActions.update(editId, this.state.distributionpoint))
                .then(response => {
                    this.close();
                });
        }
        else {
            this.props.dispatch(distributionpointActions.create(this.state.distributionpoint))
                .then(response => {
                    this.close();
                });
        }
    }
    render() {
        const { editId, size, text, loadingDistributionPoint } = this.props;
        let { distributionpoint, showmodal } = this.state;

        return (
            <div>
                {editId && <Button icon size={size ? size : 'mini'} onClick={() => this.show(editId)} primary>
                    <Icon name='edit' />
                    {text !== undefined ? text : resources.EDIT}
                </Button>}
                {editId && <Button icon size={size ? size : 'mini'} onClick={() => this.remove(editId)} negative>
                    <Icon name='remove' />
                    {text !== undefined ? text : resources.REMOVE}
                </Button>}

                {editId === undefined && <Button icon size={size ? size : 'mini'} onClick={() => this.show()} primary>
                    <Icon name='add' />
                    {text !== undefined ? text : resources.CREATE_NEW}
                </Button>}

                <Modal size='mini' open={showmodal} onClose={this.close} closeIcon>
                    <Modal.Header>
                        {editId ? resources.EDIT : resources.CREATE_NEW}
                    </Modal.Header>
                    <Modal.Content style={{ padding: '0' }}>

                        <Form onSubmit={(e) => { e.preventDefault(); this.save(editId); }}>
                            <Segment basic>
                                <Dimmer active={loadingDistributionPoint} inverted>
                                    <Loader />
                                </Dimmer>

                                <Form.Field required>
                                    <label>{resources.NUMBR}</label>
                                    <input placeholder={resources.NUMBR} required
                                        type='number' min={1} value={distributionpoint.PointNumber}
                                        onChange={(e) => this.onchange('PointNumber', e.target.value)} />
                                </Form.Field>

                                <Form.Field required>
                                    <label>{resources.NAME}</label>
                                    <input placeholder={resources.NAME} required
                                        type='text' value={distributionpoint.PointName}
                                        onChange={(e) => this.onchange('PointName', e.target.value)} />
                                </Form.Field>

                                <Form.Field required>
                                    <label>{resources.CODE}</label>
                                    <input placeholder={resources.CODE} required
                                        type='text' value={distributionpoint.PointCode}
                                        onChange={(e) => this.onchange('PointCode', e.target.value)} />
                                </Form.Field>

                                <Form.TextArea label={resources.DESCRIPTION}
                                    placeholder={resources.DESCRIPTION} value={distributionpoint.PointDescription}
                                    rows={4} onChange={(e) => this.onchange('PointDescription', e.target.value)} />



                            </Segment>
                            <Segment attached='bottom' secondary style={{margin:'0',width:'100%'}}>
                                <Button positive icon='save' type='submit' labelPosition='left' content={resources.SAVE} disabled={loadingDistributionPoint} />
                                <Button  onClick={() => this.close()}>{resources.CANCEL}</Button>
                            </Segment>
                        </Form>

                    </Modal.Content>

                </Modal>
            </div>
        )
    }
}


DistributionPointForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    distributionpoint: PropTypes.object.isRequired,
    loadingDistributionPoint: PropTypes.bool.isRequired,
    editId: PropTypes.number,
    size: PropTypes.string,
    text: PropTypes.string
}

function mapStateToProps(state) {

    const { distributionpoint, loadingDistributionPoint } = state.distributionpoint;
    return {
        distributionpoint, loadingDistributionPoint
    };
}

export default connect(mapStateToProps)(DistributionPointForm);
import { inventoryrowConstants } from '../constants/inventoryrow.constants';

const initialState = {
  loadingInventoryRow: false,
  loadingInventoryRows: false,
  error: '',
  inventoryrows: [],
  inventoryrow: {
    DistributionPointNumber: 0,
    InventoryRowIdentifier: '',
    Epc: '',
    ReadTime:  '',
    Tid: '',
    EditedByApplicationUserId: '',
    EditedByApplicationUserName: '',
    Id: 0,
    Created:  '',
    Updated:  ''
  }
};


export function inventoryrow(state = initialState, action) {
  switch (action.type) {

    // === Create ===

    case inventoryrowConstants.CREATE_INVENTORYROW_REQUEST:
      return {
        ...state,
        loadingInventoryRow: true
      };
    case inventoryrowConstants.CREATE_INVENTORYROW_SUCCESS:
      return {
        ...state,
        loadingInventoryRow: false,
        inventoryrow: { ...action.inventoryrow },
        inventoryrows: [action.inventoryrow, ...state.inventoryrows]
      };
    case inventoryrowConstants.CREATE_INVENTORYROW_FAILURE:
      return {
        ...state,
        loadingInventoryRow: false,
        error: action.error
      };

    // === Get ===

    case inventoryrowConstants.GET_INVENTORYROW_REQUEST:
      return {
        ...state,
        loadingInventoryRows: true
      };
    case inventoryrowConstants.GET_INVENTORYROW_SUCCESS:
      return {
        ...state,
        loadingInventoryRows: false,
        inventoryrows: [...action.inventoryrows]
      };
    case inventoryrowConstants.GET_INVENTORYROW_FAILURE:
      return {
        ...state,
        loadingInventoryRows: false,
        error: action.error
      };

    // === Get by ID ===

    case inventoryrowConstants.GETBYID_INVENTORYROW_REQUEST:
      return {
        ...state,
        loadingInventoryRow: true
      }
    case inventoryrowConstants.GETBYID_INVENTORYROW_SUCCESS:
      return {
        ...state,
        loadingInventoryRow: false,
        inventoryrow: { ...action.inventoryrow }
      }
    case inventoryrowConstants.GETBYID_INVENTORYROW_FAILURE:
      return {
        ...state,
        loadingInventoryRow: false,
        error: action.error
      }

    // === Update ===

    case inventoryrowConstants.UPDATE_INVENTORYROW_REQUEST:
      return {
        ...state,
        loadingInventoryRow: true
      };
    case inventoryrowConstants.UPDATE_INVENTORYROW_SUCCESS:
      return {
        ...state,
        loadingInventoryRow: false,
        inventoryrows: state.inventoryrows.map(item => {
                                        if (item.Id === action.inventoryrow.Id)
                                          return { ...action.inventoryrow };
                                        else
                                          return item;
                                      }),
        inventoryrow: { ...action.inventoryrow }
      };
    case inventoryrowConstants.UPDATE_INVENTORYROW_FAILURE:
      return {
        ...state,
        loadingInventoryRow: false,
        error: action.error
      };

    // === Remove ===

    case inventoryrowConstants.REMOVE_INVENTORYROW_REQUEST:
      return {
        ...state,
        loadingInventoryRows:true
      }
    case inventoryrowConstants.REMOVE_INVENTORYROW_SUCCESS:
      return {
        ...state,
        loadingInventoryRows:false,
        inventoryrows: state.inventoryrows.filter(item => item.Id !== action.id)
      }
    case inventoryrowConstants.REMOVE_INVENTORYROW_FAILURE:
      return {
        ...state, 
        loadingInventoryRows:false,
        error: action.error
      };

    default:
      return state;
  }
}
import React, { Component } from "react";
import resources from "../resources";
import logo from "../img/logo.svg";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import "semantic-ui-css/components/container.min.css";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import "semantic-ui-css/components/dropdown.min.css";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import "semantic-ui-css/components/image.min.css";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import "semantic-ui-css/components/menu.min.css";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import "semantic-ui-css/components/icon.min.css";
import PropTypes from "prop-types";
import "../styles/MainMenu.css";
import { loginService } from "./../services/login.service";

export default class MainMenu extends Component {
  constructor(props) {
    super(props);
    document.title = this.getDocumentTitle();
  }
  getDocumentTitle() {
    const { pathname } = this.props.location;

    if (decodeURIComponent(pathname) === "/") {
      return resources.STORAGE_BALANCE;
    } else if (decodeURIComponent(pathname).startsWith("/hälytysrajat")) {
      return resources.ALERT_AMOUNTS;
    } else if (decodeURIComponent(pathname).startsWith("/tilauslista")) {
      return resources.ORDER_LISTS;
    } else if (decodeURIComponent(pathname).startsWith("/ylläpito")) {
      return resources.MAINTENANCE;
    } else if (decodeURIComponent(pathname).startsWith("/lokit")) {
      return resources.LOGS;
    } else return "";
  }
  handleClick = () => {
    //Admin can go to frontpage
    if (
      this.props.user.Roles &&
      (this.props.user.Roles.includes("Admin") ||
        this.props.user.Roles.includes("SuperUser") ||
        this.props.user.Roles.includes("Viewer"))
    ) {
      if (this.props.location.pathname !== "/") this.props.navigate("/");
    } else {
      if (this.props.location.pathname !== "/tilauslista")
        this.props.navigate("/tilauslista");
    }
  };
  logout = (currentpathname) => {
    loginService.logout();
    if (currentpathname !== "/kirjaudu") this.props.navigate("/kirjaudu");

    window.location.reload(true);
  };
  render() {
    const { pathname } = this.props.location;
    const { Roles } = this.props.user;

    return (
      <Menu fixed="top">
        <Container>
          <Menu.Item
            className="mobile hidden"
            onClick={this.handleClick}
            header
          >
            <Image src={logo} style={{ height: "20px" }} />
          </Menu.Item>
          {Roles &&
            (Roles.includes("Admin") ||
              Roles.includes("SuperUser") ||
              Roles.includes("Viewer")) && (
              <Menu.Item
                active={pathname === "/"}
                onClick={() => {
                  if (pathname !== "/") this.props.navigate("/");
                }}
              >
                <Icon name="list layout" />{" "}
                <div className="mobile hidden">{resources.STORAGE_BALANCE}</div>
              </Menu.Item>
            )}
          {Roles &&
            Roles.includes("Admin") === false &&
            Roles.includes("SuperUser") === false &&
            Roles.includes("Viewer") === false && (
              <Menu.Item
                active={pathname === "/tilauslista"}
                onClick={() => {
                  if (pathname !== "/tilauslista")
                    this.props.navigate("/tilauslista");
                }}
              >
                <Icon name="edit" />{" "}
                <div className="mobile hidden">{resources.ORDER_LISTS}</div>
              </Menu.Item>
            )}
          {Roles &&
            (Roles.includes("Admin") ||
              Roles.includes("SuperUser") ||
              Roles.includes("Viewer")) && (
              <Dropdown item simple text={resources.MENU}>
                <Dropdown.Menu>
                  {Roles &&
                    (Roles.includes("Admin") ||
                      Roles.includes("SuperUser") ||
                      Roles.includes("Viewer")) && (
                      <Dropdown.Item
                        active={
                          decodeURIComponent(pathname) === "/hälytysrajat"
                        }
                        onClick={() => {
                          if (decodeURIComponent(pathname) !== "/hälytysrajat")
                            this.props.navigate("/hälytysrajat");
                        }}
                      >
                        <Icon name="bell" /> {resources.ALERT_AMOUNTS}
                      </Dropdown.Item>
                    )}
                  <Dropdown.Item
                    active={pathname.startsWith("/tilauslista")}
                    onClick={() => {
                      if (pathname !== "/tilauslista")
                        this.props.navigate("/tilauslista");
                    }}
                  >
                    <Icon name="edit" /> {resources.ORDER_LISTS}
                  </Dropdown.Item>
                  {Roles &&
                    (Roles.includes("Admin") ||
                      Roles.includes("SuperUser") ||
                      Roles.includes("Viewer")) && (
                      <Dropdown.Item
                        active={decodeURIComponent(pathname) === "/ylläpito"}
                        onClick={() => {
                          if (decodeURIComponent(pathname) !== "/ylläpito")
                            this.props.navigate("/ylläpito");
                        }}
                      >
                        <Icon name="setting" /> {resources.MAINTENANCE}
                      </Dropdown.Item>
                    )}
                  {Roles && Roles.includes("Admin") && (
                    <Dropdown.Item
                      active={pathname === "/lokit"}
                      onClick={() => {
                        if (pathname !== "/lokit")
                          this.props.navigate("/lokit");
                      }}
                    >
                      <Icon name="bug" /> {resources.LOGS}
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      window.open(
                        "https://app.powerbi.com/view?r=eyJrIjoiYWU1ZWEzOTUtYmQ2ZS00Mjg3LTlhOGUtYWRlNTQ0YjdjNGJjIiwidCI6ImM3M2E0NWZkLTcwNGMtNDM0OS1hYzQ5LTBhZmY0MzRlY2UzYSIsImMiOjh9",
                        resources.REPORTS,
                        "toolbar=no,scrollbars=yes,resizable=yes,top=100,left=100,width=1200,height=710"
                      );
                    }}
                  >
                    <Icon name="chart bar outline" /> {resources.REPORTS}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}

          <Menu.Item
            position="right"
            active={pathname === "/kirjaudu"}
            onClick={() => this.logout(pathname)}
          >
            <Icon name="sign out" />{" "}
            <div className="mobile hidden">{resources.LOGOUT}</div>
          </Menu.Item>
        </Container>
      </Menu>
    );
  }
}

MainMenu.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

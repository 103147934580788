import configuration from "../configuration";

export function authToken() {
  // return authorization header with jwt token
  let loginToken = JSON.parse(
    localStorage.getItem(configuration.TOKEN_LOCALSTORAGE_KEY)
  );

  if (loginToken && loginToken.token && loginToken.token.Value)
    return loginToken.token.Value;
  else return "";
}
export function authHeader() {
  // return authorization header with jwt token
  let loginToken = JSON.parse(
    localStorage.getItem(configuration.TOKEN_LOCALSTORAGE_KEY)
  );

  if (loginToken && loginToken.token && loginToken.token.Value) {
    return { Authorization: "Bearer " + loginToken.token.Value };
  } else {
    return {};
  }
}

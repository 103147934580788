import {request} from './base.service';
 
export const grudService = {
    create,
    get,
    constructQueryUrl,
    query,
    getById,
    update,
    remove
};
/**
 * Create object
 * @param {*} data 
 * @param {*} url 
 */
function create(data, url) {

    return request('POST', JSON.stringify(data), url)
        .then(response => {
            return response;
        });
}

/**
 * Get all
 * @param {*} url 
 */
function get(url) {
    
    return request('GET', null, url)
        .then(response => {
            return response;
        });
}
/**
 * Create query url with parameters
 * @param {*} url 
 * @param {*} start 
 * @param {*} rows 
 * @param {*} search 
 * @param {*} sortcolumn 
 * @param {*} sortorder 
 * @param {*} startDate 
 * @param {*} endDate 
 */
function constructQueryUrl(url, start, rows, search, sortcolumn, sortorder, startDate, endDate){
    
    if(url.endsWith('/') === false)
        url += '/';

    let queryUrl = url + '';

    if(start && start !== '')
    {
        queryUrl += queryUrl.includes('?') === false ? '?' : '&';
        queryUrl += `startfromRow=${encodeURIComponent(start)}`;
    }
    
    if(rows && rows !== '')
    {
        queryUrl += queryUrl.includes('?') === false ? '?' : '&';
        queryUrl += `rowCount=${encodeURIComponent(rows)}`;
    }
    if(search && search !== '')
    {
        queryUrl += queryUrl.includes('?') === false ? '?' : '&';
        queryUrl += `searchWord=${encodeURIComponent(search)}`;
    }

    if(startDate && startDate !== ''){

        queryUrl += queryUrl.includes('?') === false ? '?' : '&';
        queryUrl += `startDate=${encodeURIComponent(startDate)}`;
    }

    if(endDate && endDate !== ''){
        queryUrl += queryUrl.includes('?') === false ? '?' : '&';
        queryUrl += `endDate=${encodeURIComponent(endDate)}`;
    }

    if(sortcolumn && sortcolumn !== '')
    {
        queryUrl += queryUrl.includes('?') === false ? '?' : '&';
        queryUrl += `sortColumn=${encodeURIComponent(sortcolumn)}`;

        if(sortorder)
            queryUrl += `&sortOrder=${encodeURIComponent(sortorder)}`; 
        else 
            queryUrl += `&sortOrder=asc`; 
    }
    return queryUrl;
}
/**
 * Query all
 * @param {*} url 
 * @param {*} start 
 * @param {*} rows 
 * @param {*} search 
 * @param {*} sortcolumn 
 * @param {*} sortorder 
 * @param {*} startDate 
 * @param {*} endDate 
 */
function query(url, start, rows, search, sortcolumn, sortorder, startDate, endDate){

    let queryUrl = constructQueryUrl(url,start,rows,search,sortcolumn,sortorder,startDate,endDate);

    return request('GET', null, queryUrl)
        .then(response => {
            return response;
        });
}
/**
 * Get one object by id
 * @param {*} id 
 * @param {*} url 
 */
function getById(id, url) {
    
    if(url.endsWith('/') === false)
        url += '/';

    return request('GET', null, url + encodeURIComponent(id))
        .then(response => {
            return response;
        });
}
/**
 * Update by id
 * @param {*} data 
 * @param {*} url 
 */
function update(id, data, url) {
    
    if(url.endsWith('/') === false)
        url += '/';

    return request('PUT', JSON.stringify(data), url + encodeURIComponent(id))
        .then(response => {
            return response;
        });
}
/**
 * Delete object
 * @param {*} data 
 * @param {*} url 
 */
function remove(id, url) {
    
    if(url.endsWith('/') === false)
        url += '/';
        
    return request('DELETE', null, url + encodeURIComponent(id))
        .then(response => {
            return response;
        });
}
import { distributionpointConstants } from '../constants/distributionpoint.constants';
import DistributionPoint from '../models/DistributionPoint.model';

const initialState = {
  loadingDistributionPoint: false,
  loadingDistributionPoints: false,
  error: '',
  distributionpoints: [],
  distributionpoint:new DistributionPoint()
};


export function distributionpoint(state = initialState, action) {
  switch (action.type) {

    // === Create ===

    case distributionpointConstants.CREATE_DISTRIBUTIONPOINT_REQUEST:
      return {
        ...state,
        loadingDistributionPoint: true
      };
    case distributionpointConstants.CREATE_DISTRIBUTIONPOINT_SUCCESS:
      return {
        ...state,
        loadingDistributionPoint: false,
        distributionpoint: { ...action.distributionpoint },
        distributionpoints: [action.distributionpoint, ...state.distributionpoints]
      };
    case distributionpointConstants.CREATE_DISTRIBUTIONPOINT_FAILURE:
      return {
        ...state,
        loadingDistributionPoint: false,
        error: action.error
      };

    // === Get ===

    case distributionpointConstants.GET_DISTRIBUTIONPOINT_REQUEST:
      return {
        ...state,
        loadingDistributionPoints: true
      };
    case distributionpointConstants.GET_DISTRIBUTIONPOINT_SUCCESS:
      return {
        ...state,
        loadingDistributionPoints: false,
        distributionpoints: [...action.distributionpoints.filter(i => i.PointNumber !== 0)]
      };
    case distributionpointConstants.GET_DISTRIBUTIONPOINT_FAILURE:
      return {
        ...state,
        loadingDistributionPoints: false,
        error: action.error
      };

    // === Get by ID ===

    case distributionpointConstants.GETBYID_DISTRIBUTIONPOINT_REQUEST:
      return {
        ...state,
        loadingDistributionPoint: true
      }
    case distributionpointConstants.GETBYID_DISTRIBUTIONPOINT_SUCCESS:
      return {
        ...state,
        loadingDistributionPoint: false,
        distributionpoint: { ...action.distributionpoint }
      }
    case distributionpointConstants.GETBYID_DISTRIBUTIONPOINT_FAILURE:
      return {
        ...state,
        loadingDistributionPoint: false,
        error: action.error
      }

    // === Update ===

    case distributionpointConstants.UPDATE_DISTRIBUTIONPOINT_REQUEST:
      return {
        ...state,
        loadingDistributionPoint: true
      };
    case distributionpointConstants.UPDATE_DISTRIBUTIONPOINT_SUCCESS:
      return {
        ...state,
        loadingDistributionPoint: false,
        distributionpoints: state.distributionpoints.map(item => {
                                        if (item.Id === action.distributionpoint.Id)
                                          return { ...action.distributionpoint };
                                        else
                                          return item;
                                      }),
        distributionpoint: { ...action.distributionpoint }
      };
    case distributionpointConstants.UPDATE_DISTRIBUTIONPOINT_FAILURE:
      return {
        ...state,
        loadingDistributionPoint: false,
        error: action.error
      };

    // === Remove ===

    case distributionpointConstants.REMOVE_DISTRIBUTIONPOINT_REQUEST:
      return {
        ...state,
        loadingDistributionPoints:true
      }
    case distributionpointConstants.REMOVE_DISTRIBUTIONPOINT_SUCCESS:
      return {
        ...state,
        loadingDistributionPoints:false,
        distributionpoints: state.distributionpoints.filter(item => item.Id !== action.id)
      }
    case distributionpointConstants.REMOVE_DISTRIBUTIONPOINT_FAILURE:
      return {
        ...state, 
        loadingDistributionPoints:false,
        error: action.error
      };

    default:
      return state;
  }
}
import React, { Component } from 'react'
import resources from '../resources';
import List from 'semantic-ui-react/dist/commonjs/elements/List';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';
import TextArea from 'semantic-ui-react/dist/commonjs/addons/TextArea';
import moment from 'moment';
import PropTypes from 'prop-types';
import { loglevelConstants } from '../constants/loglevel.constants';

export default class LogSummary extends Component {

  render() {

    const { log } = this.props;

    return (
      <Segment basic style={{ padding: '0' }}>
        <Dimmer active={this.props.loading} inverted>
          <Loader />
        </Dimmer>
        <List>

          <List.Item>
            <Icon name='calendar' />
            <List.Content>
              <List.Header>{resources.CREATED}</List.Header>
              <List.Description>
                <br />
                {moment(log.Created).toISOString()}
                <br />
                <br />
              </List.Description>
            </List.Content>
          </List.Item>

          <List.Item>
            <Icon name={log.LogLevel === loglevelConstants.Info ? 'info circle' :
              (log.LogLevel === loglevelConstants.Warning ? 'warning sign' :
                (log.LogLevel === loglevelConstants.Error ? 'remove circle' : 'bug'))} />
            <List.Content>
              <List.Header>{resources.LOGLEVEL}</List.Header>
              <List.Description>
                <br />
                {log.LogLevel + ' ' + (log.LogLevel === loglevelConstants.Info ? resources.INFO :
                  (log.LogLevel === loglevelConstants.Warning ? resources.WARNING :
                  (log.LogLevel === loglevelConstants.Error ? resources.ERROR : '')))} &nbsp;
                <br />
                <br /> 
              </List.Description>
            </List.Content>
          </List.Item>

          <List.Item>
            <Icon name='pencil' />
            <List.Content>
              <List.Header>{resources.TITLE}</List.Header>
              <List.Description>
                <br />
                {log.Title} &nbsp;
                <br />
                <br />
              </List.Description>
            </List.Content>
          </List.Item>

          <List.Item>
            <Icon name='ticket' />
            <List.Content>
              <List.Header>{resources.EVENT}</List.Header>
              <List.Description>
                <br />
                {log.EventId}&nbsp;
                <br />
                <br />
              </List.Description>
            </List.Content>
          </List.Item>

          <List.Item className='fullwidth'>
            <Icon name='info circle' />
            <List.Content>
              <List.Header>{resources.MESSAGE}</List.Header>
              <List.Description>
                <br />
                <TextArea rows={20} value={log.Message} />
                <br />
                <br />
              </List.Description>
            </List.Content>
          </List.Item>


          <List.Item>
            <Icon name='map pin' />
            <List.Content>
              <List.Header>{resources.SOURCE}</List.Header>
              <List.Description>
                <br />
                {log.Source} &nbsp;
                <br />
                <br />
              </List.Description>
            </List.Content>
          </List.Item>

          <List.Item>
            <Icon name='user circle' />
            <List.Content>
              <List.Header>{resources.USER}</List.Header>
              <List.Description>
                <br />
                {log.ApplicationUserId === '00000000-0000-0000-0000-000000000000' ? '' : log.ApplicationUserId}  &nbsp;
                <br />
                <br />
              </List.Description>
            </List.Content>
          </List.Item>

          <List.Item>
            <Icon name='group' />
            <List.Content>
              <List.Header>{resources.CLIENT_ID}</List.Header>
              <List.Description>
                <br />
                {log.ClientId} &nbsp;
                <br />
                <br />
              </List.Description>
            </List.Content>
          </List.Item>


        </List>
      </Segment>
    )
  }
}

LogSummary.propTypes = {
  log: PropTypes.object.isRequired
}
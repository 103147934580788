import Grud from './grud.service';
import {grudService} from './base.grud.service';

class ProductPointLimitService extends Grud {
    constructor() {
      super('productpointlimit');
    }
    /**
     * Create objects, add or update based on id
     * @param {*} data - array
     * @param {*} url 
     */
    addorupdate(data) {
      return grudService.create(data, this.apiUrl + 'addorupdate');
    }
}
export default ProductPointLimitService;
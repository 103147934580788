import { combineReducers } from "redux";

import { alert } from "./alert.reducer";
import { alertrow } from "./alertrow.reducer";
import { amountsinstorage } from "./amountsinstorage.reducer";
import { distributionpoint } from "./distributionpoint.reducer";
import { inventory } from "./inventory.reducer";
import { inventoryrow } from "./inventoryrow.reducer";
import { log } from "./log.reducer";
import { login } from "./login.reducer";
import { orderrow } from "./orderrow.reducer";
import { product } from "./product.reducer";
import { productpointlimit } from "./productpointlimit.reducer";
import { productrow } from "./productrow.reducer";

const rootReducer = combineReducers({
  alert,
  alertrow,
  amountsinstorage,
  distributionpoint,
  inventory,
  inventoryrow,
  log,
  login,
  orderrow,
  product,
  productpointlimit,
  productrow,
});

export default rootReducer;

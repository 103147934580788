import { amountsinstorageConstants } from "../constants/amountsinstorage.constants";

const initialState = {
  loadingAmountsInStorages: false,
  error: "",
  amountsinstorages: [],
  amountsinstorage: {
    DistributionPointNumber: 0,
    DistributionPointName: "",
    Created: "",
    ProductCode: "",
    ProductName: "",
    ProductSize: "",
    ProductColor: "",
    Amount: 0,
    GoalAmount: 0,
    OrderLimit: 0,
    AlertLimit: 0,
  },
};

export function amountsinstorage(state = initialState, action) {
  switch (action.type) {
    // === Get ===

    case amountsinstorageConstants.GET_AMOUNTSINSTORAGE_REQUEST:
      return {
        ...state,
        loadingAmountsInStorages: true,
      };
    case amountsinstorageConstants.GET_AMOUNTSINSTORAGE_SUCCESS:
      return {
        ...state,
        loadingAmountsInStorages: false,
        amountsinstorages: [...action.amountsinstorages],
      };
    case amountsinstorageConstants.GET_AMOUNTSINSTORAGE_FAILURE:
      return {
        ...state,
        loadingAmountsInStorages: false,
        error: action.error,
      };
    case amountsinstorageConstants.CLEAR_AMOUNTSINSTORAGE:
      return {
        ...state,
        amountsinstorages: [],
      };
    default:
      return state;
  }
}

import { loginConstants } from "../constants/login.constants";
import { loginService } from "../services/login.service";
import { alertActions } from "./alert.actions";

export const loginActions = {
  login,
  logout,
};

function login(username, password, navigate) {
  return (dispatch) => {
    dispatch(request({ username }));

    loginService.login(username, password).then(
      (token) => {
        dispatch(success(token));

        if (token && token.user && token.user.Roles) {
          //If admin or superuser, then redirect to root page
          if (
            token.user.Roles.includes("Admin") ||
            token.user.Roles.includes("SuperUser") ||
            token.user.Roles.includes("Viewer")
          )
            navigate("/");
          //If basic user => redirect to tilauslista -page
          else navigate("/tilauslista");
        }
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(token) {
    return { type: loginConstants.LOGIN_REQUEST, token };
  }
  function success(token) {
    return { type: loginConstants.LOGIN_SUCCESS, token };
  }
  function failure(error) {
    return { type: loginConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  loginService.logout();
  return { type: loginConstants.LOGOUT };
}

import { orderrowConstants } from '../constants/orderrow.constants';
import OrderRow from '../models/OrderRow.model';

const initialState = {
  loadingOrderRow: false,
  loadingOrderRows: false,
  error: '',
  orderrows: [],
  latestorderrows: [],
  orderrow: new OrderRow()
};


export function orderrow(state = initialState, action) {
  switch (action.type) {

    // === Create ===

    case orderrowConstants.CREATE_ORDERROW_REQUEST:
      return {
        ...state,
        loadingOrderRow: true
      };
    case orderrowConstants.CREATE_ORDERROW_SUCCESS:
      return {
        ...state,
        loadingOrderRow: false,
        orderrow: { ...action.orderrow },
        orderrows: [action.orderrow, ...state.orderrows]
      };
    case orderrowConstants.CREATE_ORDERROW_FAILURE:
      return {
        ...state,
        loadingOrderRow: false,
        error: action.error
      };

    // === Get ===

    case orderrowConstants.GET_ORDERROW_REQUEST:
      return {
        ...state,
        loadingOrderRows: true
      };
    case orderrowConstants.GET_ORDERROW_SUCCESS:
      return {
        ...state,
        loadingOrderRows: false,
        orderrows: [...action.orderrows]
      };
    case orderrowConstants.GET_ORDERROW_FAILURE:
      return {
        ...state,
        loadingOrderRows: false,
        error: action.error
      };


    // === Get by distributionpoint ===

    case orderrowConstants.GET_ORDERROWFORDISTRIBUTIONPOINT_REQUEST:
      return {
        ...state,
        loadingOrderRows: true
      };
    case orderrowConstants.GET_ORDERROWFORDISTRIBUTIONPOINT_SUCCESS:
      return {
        ...state,
        loadingOrderRows: false,
        orderrows: [...action.orderrows]
      };
    case orderrowConstants.GET_ORDERROWFORDISTRIBUTIONPOINT_FAILURE:
      return {
        ...state,
        loadingOrderRows: false,
        error: action.error
      };

    // === Get latest by distributionpoint ===

    case orderrowConstants.GET_LATEST_ORDERROWFORDISTRIBUTIONPOINT_REQUEST:
      return {
        ...state,
        loadingOrderRows: true
      };
    case orderrowConstants.GET_LATEST_ORDERROWFORDISTRIBUTIONPOINT_SUCCESS:
      return {
        ...state,
        loadingOrderRows: false,
        latestorderrows: [...action.orderrows]
      };
    case orderrowConstants.GET_LATEST_ORDERROWFORDISTRIBUTIONPOINT_FAILURE:
      return {
        ...state,
        loadingOrderRows: false,
        error: action.error
      };

    // === Get by ID ===

    case orderrowConstants.GETBYID_ORDERROW_REQUEST:
      return {
        ...state,
        loadingOrderRow: true
      }
    case orderrowConstants.GETBYID_ORDERROW_SUCCESS:
      return {
        ...state,
        loadingOrderRow: false,
        orderrow: { ...action.orderrow }
      }
    case orderrowConstants.GETBYID_ORDERROW_FAILURE:
      return {
        ...state,
        loadingOrderRow: false,
        error: action.error
      }

    // === Update ===

    case orderrowConstants.UPDATE_ORDERROW_REQUEST:
      return {
        ...state,
        loadingOrderRow: true
      };
    case orderrowConstants.UPDATE_ORDERROW_SUCCESS:
      return {
        ...state,
        loadingOrderRow: false,
        orderrows: state.orderrows.map(item => {
          if (item.Id === action.orderrow.Id)
            return { ...action.orderrow };
          else
            return item;
        }),
        orderrow: { ...action.orderrow }
      };
    case orderrowConstants.UPDATE_ORDERROW_FAILURE:
      return {
        ...state,
        loadingOrderRow: false,
        error: action.error
      };

    // === Remove ===

    case orderrowConstants.REMOVE_ORDERROW_REQUEST:
      return {
        ...state,
        loadingOrderRows: true
      }
    case orderrowConstants.REMOVE_ORDERROW_SUCCESS:
      return {
        ...state,
        loadingOrderRows: false,
        orderrows: state.orderrows.filter(item => item.Id !== action.id)
      }
    case orderrowConstants.REMOVE_ORDERROW_FAILURE:
      return {
        ...state,
        loadingOrderRows: false,
        error: action.error
      };

    // === Create for distributionpoint ===

    case orderrowConstants.CREATE_FORDISTRIBUTIONPOINT_REQUEST:
      return {
        ...state,
        loadingOrderRow: true
      };
    case orderrowConstants.CREATE_FORDISTRIBUTIONPOINT_SUCCESS:
      return {
        ...state,
        loadingOrderRow: false
      };
    case orderrowConstants.CREATE_FORDISTRIBUTIONPOINT_FAILURE:
      return {
        ...state,
        loadingOrderRow: false,
        error: action.error
      };
    default:
      return state;
  }
}
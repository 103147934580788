import { orderrowConstants }  from '../constants/orderrow.constants';
import { alertActions } from './alert.actions';
import resources from '../resources';
import OrderRowService from '../services/orderrow.service';
 
export const orderrowActions = {  
    create,
    get,
    getById,
    getByDistributionPoint,
    getLatestByDistributionPoint,
    getDataByDistributionPoint,
    update,
    remove,
    createByDistributionpoint
};
/**
 * Create object action
 * @param {*} data 
 */
function create(data) {
    return dispatch => {
        dispatch(request(data));

        return new OrderRowService().create(data)
                        .then(
                            orderrow => {
                                dispatch(success(orderrow));
                                dispatch(alertActions.success(resources.SAVE_SUCCESS));
                                return orderrow;
                            },
                            error => {
                                dispatch(failure(error, data));
                                dispatch(alertActions.error(resources.SAVE_FAILED + ': ' + error));
                                return error;
                            }
                        );
    };

    function request(orderrow) { return { type: orderrowConstants.CREATE_ORDERROW_REQUEST, orderrow } }
    function success(orderrow) { return { type: orderrowConstants.CREATE_ORDERROW_SUCCESS, orderrow } }
    function failure(error, orderrow) { return { type: orderrowConstants.CREATE_ORDERROW_FAILURE, error, orderrow } }
}

/**
 * Query object array action
 * @param {*} start 
 * @param {*} rows 
 * @param {*} search 
 * @param {*} sortcolumn 
 * @param {*} sortorder 
 */
function get(start, rows, search, sortcolumn, sortorder) {
    return dispatch => {
        dispatch(request());

        new OrderRowService().query(start,rows,search,sortcolumn,sortorder)
                        .then(
                            orderrows => dispatch(success(orderrows)),
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.GET_FAILED + ': ' +error));
                            }
                        );
    };

    function request() { return { type: orderrowConstants.GET_ORDERROW_REQUEST } }
    function success(orderrows) { return { type: orderrowConstants.GET_ORDERROW_SUCCESS, orderrows } }
    function failure(error) { return { type: orderrowConstants.GET_ORDERROW_FAILURE, error } }
}

/**
 * Query object 
 * @param {*} id
 */
function getById(id) {
    return dispatch => {
        dispatch(request(id));

        new OrderRowService().getById(id)
                        .then(
                            orderrow => dispatch(success(orderrow)),
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.GET_FAILED + ': ' + error));
                            }
                        );
    };

    function request(id) { return { type: orderrowConstants.GETBYID_ORDERROW_REQUEST, id} }
    function success(orderrow) { return { type: orderrowConstants.GETBYID_ORDERROW_SUCCESS, orderrow } }
    function failure(error) { return { type: orderrowConstants.GETBYID_ORDERROW_FAILURE, error } }
}

/**
 * Query by distributionpoint
 * @param {*} distributionPointNumber
 */
function getByDistributionPoint(distributionPointNumber,start, rows, search, sortcolumn, sortorder) {
    return dispatch => {
        dispatch(request(distributionPointNumber));

        return new OrderRowService().getByDistributionPoint(distributionPointNumber, start, rows, search, sortcolumn, sortorder)
                        .then(
                            orderrows => {
                                dispatch(success(orderrows));
                                return orderrows;
                            },
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.GET_FAILED + ': ' + error));
                                return error;
                            }
                        );
    };

    function request(distributionPointNumber) { return { type: orderrowConstants.GET_ORDERROWFORDISTRIBUTIONPOINT_REQUEST, distributionPointNumber} }
    function success(orderrows) { return { type: orderrowConstants.GET_ORDERROWFORDISTRIBUTIONPOINT_SUCCESS, orderrows } }
    function failure(error) { return { type: orderrowConstants.GET_ORDERROWFORDISTRIBUTIONPOINT_FAILURE, error } }
}
/**
 * Query rows by int32 max value
 * return array of objects
 * @param {*} latest 
 * @param {*} distributionPointNumber 
 * @param {*} start 
 * @param {*} rows 
 * @param {*} search 
 * @param {*} sortcolumn 
 * @param {*} sortorder 
 */
function getDataByDistributionPoint(latest, distributionPointNumber, search, sortcolumn, sortorder) {
    

    return (
            latest ? 
            new OrderRowService().queryLatest(distributionPointNumber, 0, 2147483647, search, sortcolumn, sortorder) : 
            new OrderRowService().getByDistributionPoint(distributionPointNumber, 0, 2147483647, search, sortcolumn, sortorder)
        )
        .then(
            orderrows => {
                return orderrows
            },
            error => {
               return error;
            }
        );
}
/**
 * Query latest by distributionpoint
 * @param {*} distributionPointNumber 
 * @param {*} start 
 * @param {*} rows 
 * @param {*} search 
 * @param {*} sortcolumn 
 * @param {*} sortorder 
 */
function getLatestByDistributionPoint(distributionPointNumber,start, rows, search, sortcolumn, sortorder, dispatchResults) {
    return dispatch => {
        dispatch(request(distributionPointNumber));

        return new OrderRowService().queryLatest(distributionPointNumber, start, rows, search, sortcolumn, sortorder)
                        .then(
                            orderrows => {
                                dispatch(success(orderrows))
                                return orderrows;
                            },
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.GET_FAILED + ': ' + error));
                                return error;
                            }
                        );
    };

    function request(distributionPointNumber) { return { type: orderrowConstants.GET_LATEST_ORDERROWFORDISTRIBUTIONPOINT_REQUEST, distributionPointNumber} }
    function success(orderrows) { return { type: orderrowConstants.GET_LATEST_ORDERROWFORDISTRIBUTIONPOINT_SUCCESS, orderrows } }
    function failure(error) { return { type: orderrowConstants.GET_LATEST_ORDERROWFORDISTRIBUTIONPOINT_FAILURE, error } }
}
/**
 * Update
 * @param {*} id
 */
function update(id,data) {
    return dispatch => {
        dispatch(request(data));

        new OrderRowService().update(id,data)
                        .then(
                            orderrow => {
                                dispatch(success(orderrow));
                                dispatch(alertActions.success(resources.SAVE_SUCCESS));
                            },
                            error => {
                                dispatch(failure(error, data));
                                dispatch(alertActions.error(resources.SAVE_FAILED + ': ' + error));
                            }
                        );
    };

    function request(orderrow) { return { type: orderrowConstants.UPDATE_ORDERROW_REQUEST, orderrow } }
    function success(orderrow) { return { type: orderrowConstants.UPDATE_ORDERROW_SUCCESS, orderrow } }
    function failure(error, orderrow) { return { type: orderrowConstants.UPDATE_ORDERROW_FAILURE, error, orderrow } }
}

/**
 * Delete
 * @param {*} id
 */
function remove(id) {
    return dispatch => {
        dispatch(request());

        new OrderRowService().remove(id)
                        .then(
                            orderrow => {
                                dispatch(success(orderrow));
                                dispatch(alertActions.success(resources.DELETE_SUCCESS));
                            },
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.DELETE_FAILED + ': ' + error));
                            }
                        );
    };

    function request() { return { type: orderrowConstants.REMOVE_ORDERROW_REQUEST, id } }
    function success() { return { type: orderrowConstants.REMOVE_ORDERROW_SUCCESS, id } }
    function failure(error) { return { type: orderrowConstants.REMOVE_ORDERROW_FAILURE, error } }
}
/**
 * Create by point
 * @param {*} data 
 */
function createByDistributionpoint(distributionpointNumber) {
    return dispatch => {
        dispatch(request(distributionpointNumber));

        return new OrderRowService().createByDistributionPoint(distributionpointNumber)
                        .then(
                            response => {
                                dispatch(success());
                                dispatch(alertActions.success( resources.CREATE_BY_DISTRIBUTIONPOINT_INFO));
                                return response;
                            },
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.SAVE_FAILED + ': ' + error));
                                return error;
                            }
                        );
    };

    function request() { return { type: orderrowConstants.CREATE_FORDISTRIBUTIONPOINT_REQUEST } }
    function success() { return { type: orderrowConstants.CREATE_FORDISTRIBUTIONPOINT_SUCCESS } }
    function failure(error) { return { type: orderrowConstants.CREATE_FORDISTRIBUTIONPOINT_FAILURE, error } }
}
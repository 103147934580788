import React, { Component } from "react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import Tab from "semantic-ui-react/dist/commonjs/modules/Tab";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import resources from "../resources";
import MainMenu from "../components/MainMenu.component";
import "../styles/Maintenance.css";
import DistributionPointTable from "../containers/DistributionPointTable.container";
import ProductImport from "../containers/ProductImport.container";

class Maintenance extends Component {
  render() {
    let menuItems = [
      {
        menuItem: resources.CURRENT_STATE,
        render: () => (
          <Segment attached="bottom">
            <DistributionPointTable
              create={!this.props.user.Roles.includes("Viewer")}
            />
          </Segment>
        ),
      },
    ];

    if (!this.props.user.Roles.includes("Viewer")) {
      menuItems.push({
        menuItem: resources.PRODUCT_IMPORT,
        render: () => (
          <Segment attached="bottom">
            <ProductImport />
          </Segment>
        ),
      });
    }
    return (
      <div>
        <MainMenu
          history={this.props.history}
          location={this.props.location}
          user={this.props.user}
          navigate={this.props.navigate}
        />

        <Container fluid className="main">
          <Grid columns={1} relaxed padded stackable>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3" color="red" className="finnid-header">
                  {resources.MAINTENANCE}
                </Header>

                <Tab attached="top" panes={menuItems} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default Maintenance;

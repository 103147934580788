import { alertrowConstants } from '../constants/alertrow.constants';
import AlertRow from '../models/AlertRow.model';

const initialState = {
  loadingAlertRow: false,
  loadingAlertRows: false,
  error: '',
  alertrows: [],
  latestalertrows: [],
  alertrow: new AlertRow()
};


export function alertrow(state = initialState, action) {
  switch (action.type) {

    // === Create ===

    case alertrowConstants.CREATE_ALERTROW_REQUEST:
      return {
        ...state,
        loadingAlertRow: true
      };
    case alertrowConstants.CREATE_ALERTROW_SUCCESS:
      return {
        ...state,
        loadingAlertRow: false,
        alertrow: { ...action.alertrow },
        alertrows: [action.alertrow, ...state.alertrows]
      };
    case alertrowConstants.CREATE_ALERTROW_FAILURE:
      return {
        ...state,
        loadingAlertRow: false,
        error: action.error
      };

    // === Get ===

    case alertrowConstants.GET_ALERTROW_REQUEST:
      return {
        ...state,
        loadingAlertRows: true
      };
    case alertrowConstants.GET_ALERTROW_SUCCESS:
      return {
        ...state,
        loadingAlertRows: false,
        alertrows: [...action.alertrows]
      };
    case alertrowConstants.GET_ALERTROW_FAILURE:
      return {
        ...state,
        loadingAlertRows: false,
        error: action.error
      };
    
    // === Get by distributionpoint ===

    case alertrowConstants.GET_ALERTROWFORDISTRIBUTIONPOINT_REQUEST:
      return {
        ...state,
        loadingAlertRows: true
      };
    case alertrowConstants.GET_ALERTROWFORDISTRIBUTIONPOINT_SUCCESS:
      return {
        ...state,
        loadingAlertRows: false,
        alertrows: [...action.alertrows]
      };
    case alertrowConstants.GET_ALERTROWFORDISTRIBUTIONPOINT_FAILURE:
      return {
        ...state,
        loadingAlertRows: false,
        error: action.error
      };

    // === Get latest by distributionpoint ===

    case alertrowConstants.GET_LATEST_ALERTROWFORDISTRIBUTIONPOINT_REQUEST:
      return {
        ...state,
        loadingAlertRows: true
      };
    case alertrowConstants.GET_LATEST_ALERTROWFORDISTRIBUTIONPOINT_SUCCESS:
      return {
        ...state,
        loadingAlertRows: false,
        latestalertrows: [...action.alertrows]
      };
    case alertrowConstants.GET_LATEST_ALERTROWFORDISTRIBUTIONPOINT_FAILURE:
      return {
        ...state,
        loadingAlertRows: false,
        error: action.error
      };
    // === Get by ID ===

    case alertrowConstants.GETBYID_ALERTROW_REQUEST:
      return {
        ...state,
        loadingAlertRow: true
      }
    case alertrowConstants.GETBYID_ALERTROW_SUCCESS:
      return {
        ...state,
        loadingAlertRow: false,
        alertrow: { ...action.alertrow }
      }
    case alertrowConstants.GETBYID_ALERTROW_FAILURE:
      return {
        ...state,
        loadingAlertRow: false,
        error: action.error
      }

    // === Update ===

    case alertrowConstants.UPDATE_ALERTROW_REQUEST:
      return {
        ...state,
        loadingAlertRow: true
      };
    case alertrowConstants.UPDATE_ALERTROW_SUCCESS:
      return {
        ...state,
        loadingAlertRow: false,
        alertrows: state.alertrows.map(item => {
          if (item.Id === action.alertrow.Id)
            return { ...action.alertrow };
          else
            return item;
        }),
        alertrow: { ...action.alertrow }
      };
    case alertrowConstants.UPDATE_ALERTROW_FAILURE:
      return {
        ...state,
        loadingAlertRow: false,
        error: action.error
      };

    // === Remove ===

    case alertrowConstants.REMOVE_ALERTROW_REQUEST:
      return {
        ...state,
        loadingAlertRows: true
      }
    case alertrowConstants.REMOVE_ALERTROW_SUCCESS:
      return {
        ...state,
        loadingAlertRows: false,
        alertrows: state.alertrows.filter(item => item.Id !== action.id)
      }
    case alertrowConstants.REMOVE_ALERTROW_FAILURE:
      return {
        ...state,
        loadingAlertRows: false,
        error: action.error
      };

    default:
      return state;
  }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import resources from '../resources';
import '../styles/DistributionPointTable.css';
import DistributionPointForm from './DistributionPointForm.container';
import BaseTable from '../components/BaseTable.component';
import moment from 'moment';
import { distributionpointActions } from '../actions/distributionpoint.actions';


class DistributionPointTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search:'',
            sortcolumn:'',
            sortorder:''
        };
        this.loadData = this.loadData.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.sortby = this.sortby.bind(this);
    }

    componentDidMount(){
        this.loadData();
    }
    sortby(col,order){
        this.setState({ sortcolumn : col, sortorder : order}, 
            () => {
                this.loadData();
            });
    }
    constructHeaders(){
        let itemHeaders = [
            {value:resources.NUMBR, name:'PointNumber'},
            {value:resources.NAME, name:'PointName'},
            {value:resources.CODE, name:'PointCode'},
            {value:resources.DESCRIPTION, name:'PointDescription'},
            {value:resources.UPDATED, name:'Updated'}
        ];
        if(this.props.create){
            itemHeaders.push({width:1, value:''});
        }
        return itemHeaders;
    }

    constructRows(distributionpoints){
        return distributionpoints.map((point, index) => {

            let itemcells = [{
                value : point.PointNumber
            },{
                value : point.PointName
            },{
                value : point.PointCode
            },{
                value : point.PointDescription,
                style: { whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'200px' }
            },{
                value : moment(point.Updated).format('D.M.YYYY HH:mm')
            }];

            if(this.props.create){
                itemcells.push({
                    custom: <DistributionPointForm editId={point.Id} size='mini' text='' />,
                    style:{whiteSpace:'nowrap'}
                            
                });
            }
            return { cells:itemcells  };

      });
    }
  
    handleSearchChange = (e, { value }) => {

        this.setState({ search: value }, () => {

            if (this.searchTimeout && this.searchTimeout !== null) {
                clearTimeout(this.searchTimeout);
                this.searchTimeout = null;
            }
            this.searchTimeout = setTimeout(() => {

                this.loadData();

            }, 500);
        });
    }
    loadData(start = 0, rows = 15) {
        let { search, sortcolumn, sortorder } = this.state;
        
        this.props.dispatch(distributionpointActions.get(start, rows, search, sortcolumn, sortorder));
    }

    render() {

        const { loadingDistributionPoints,distributionpoints, create } = this.props;
        return (

            <div>
                <Menu secondary stackable>
                    <Menu.Menu position='left'>
                        <Menu.Item>

                            {create && <DistributionPointForm text={resources.CREATE_NEW_DISTRIBUTIONPOINT} size='medium' />}

                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Input icon='search' placeholder={resources.SEARCH + '...'} onChange={this.handleSearchChange} />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>

                <BaseTable
                    loading={loadingDistributionPoints}
                    headers={this.constructHeaders()}
                    rows={this.constructRows(distributionpoints)}
                    loadEntries={this.loadData}
                    sortby={this.sortby}
                />
            </div>
        )
    }
}


DistributionPointTable.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loadingDistributionPoints: PropTypes.bool.isRequired,
    distributionpoints: PropTypes.array.isRequired,
    create: PropTypes.bool
}

function mapStateToProps(state) {

    const { distributionpoints, loadingDistributionPoints } = state.distributionpoint;
    return {
        distributionpoints, loadingDistributionPoints
    };
}

export default connect(mapStateToProps)(DistributionPointTable);
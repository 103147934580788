import { alertrowConstants }  from '../constants/alertrow.constants';
import { alertActions } from './alert.actions';
import resources from '../resources';
import AlertRowService from '../services/alertrow.service';
 
export const alertrowActions = {  
    create,
    get,
    getById,
    getByDistributionPoint,
    getLatestByDistributionPoint,
    getDataByDistributionPoint,
    update,
    remove
};
/**
 * Create object action
 * @param {*} data 
 */
function create(data) {
    return dispatch => {
        dispatch(request(data));

        return new AlertRowService().create(data)
                        .then(
                            alertrow => {
                                dispatch(success(alertrow));
                                dispatch(alertActions.success(resources.SAVE_SUCCESS));
                                return alertrow;
                            },
                            error => {
                                dispatch(failure(error, data));
                                dispatch(alertActions.error(resources.SAVE_FAILED + ': ' + error));
                                return error;
                            }
                        );
    };

    function request(alertrow) { return { type: alertrowConstants.CREATE_ALERTROW_REQUEST, alertrow } }
    function success(alertrow) { return { type: alertrowConstants.CREATE_ALERTROW_SUCCESS, alertrow } }
    function failure(error, alertrow) { return { type: alertrowConstants.CREATE_ALERTROW_FAILURE, error, alertrow } }
}

/**
 * Query object array action
 * @param {*} start 
 * @param {*} rows 
 * @param {*} search 
 * @param {*} sortcolumn 
 * @param {*} sortorder 
 */
function get(start, rows, search, sortcolumn, sortorder) {
    return dispatch => {
        dispatch(request());

        new AlertRowService().query(start,rows,search,sortcolumn,sortorder)
                        .then(
                            alertrows => dispatch(success(alertrows)),
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.GET_FAILED + ': ' +error));
                            }
                        );
    };

    function request() { return { type: alertrowConstants.GET_ALERTROW_REQUEST } }
    function success(alertrows) { return { type: alertrowConstants.GET_ALERTROW_SUCCESS, alertrows } }
    function failure(error) { return { type: alertrowConstants.GET_ALERTROW_FAILURE, error } }
}



/**
 * Query by distributionpoint
 * @param {*} distributionPointNumber
 */
function getByDistributionPoint(distributionPointNumber,start, rows, search, sortcolumn, sortorder) {
    return dispatch => {
        dispatch(request(distributionPointNumber));

        return new AlertRowService().getByDistributionPoint(distributionPointNumber, start, rows, search, sortcolumn, sortorder)
                        .then(
                            alertrows => {
                                dispatch(success(alertrows));
                                return alertrows;
                            },
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.GET_FAILED + ': ' + error));
                                return error;
                            }
                        );
    };

    function request(distributionPointNumber) { return { type: alertrowConstants.GET_ALERTROWFORDISTRIBUTIONPOINT_REQUEST, distributionPointNumber} }
    function success(alertrows) { return { type: alertrowConstants.GET_ALERTROWFORDISTRIBUTIONPOINT_SUCCESS, alertrows } }
    function failure(error) { return { type: alertrowConstants.GET_ALERTROWFORDISTRIBUTIONPOINT_FAILURE, error } }
}
/**
 * Query rows by int32 max value
 * return array of objects
 * @param {*} latest 
 * @param {*} distributionPointNumber 
 * @param {*} start 
 * @param {*} rows 
 * @param {*} search 
 * @param {*} sortcolumn 
 * @param {*} sortorder 
 */
function getDataByDistributionPoint(latest, distributionPointNumber, search, sortcolumn, sortorder) {
    

    return (
            latest ? 
            new AlertRowService().queryLatest(distributionPointNumber, 0, 2147483647, search, sortcolumn, sortorder) : 
            new AlertRowService().getByDistributionPoint(distributionPointNumber, 0, 2147483647, search, sortcolumn, sortorder)
        )
        .then(
            alertrows => {
                return alertrows
            },
            error => {
               return error;
            }
        );
}
/**
 * Query latest by distributionpoint
 * @param {*} distributionPointNumber 
 * @param {*} start 
 * @param {*} rows 
 * @param {*} search 
 * @param {*} sortcolumn 
 * @param {*} sortorder 
 */
function getLatestByDistributionPoint(distributionPointNumber,start, rows, search, sortcolumn, sortorder, dispatchResults) {
    return dispatch => {
        dispatch(request(distributionPointNumber));

        return new AlertRowService().queryLatest(distributionPointNumber, start, rows, search, sortcolumn, sortorder)
                        .then(
                            alertrows => {
                                dispatch(success(alertrows))
                                return alertrows;
                            },
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.GET_FAILED + ': ' + error));
                                return error;
                            }
                        );
    };

    function request(distributionPointNumber) { return { type: alertrowConstants.GET_LATEST_ALERTROWFORDISTRIBUTIONPOINT_REQUEST, distributionPointNumber} }
    function success(alertrows) { return { type: alertrowConstants.GET_LATEST_ALERTROWFORDISTRIBUTIONPOINT_SUCCESS, alertrows } }
    function failure(error) { return { type: alertrowConstants.GET_LATEST_ALERTROWFORDISTRIBUTIONPOINT_FAILURE, error } }
}


/**
 * Query object 
 * @param {*} id
 */
function getById(id) {
    return dispatch => {
        dispatch(request(id));

        new AlertRowService().getById(id)
                        .then(
                            alertrow => dispatch(success(alertrow)),
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.GET_FAILED + ': ' + error));
                            }
                        );
    };

    function request(id) { return { type: alertrowConstants.GETBYID_ALERTROW_REQUEST, id} }
    function success(alertrow) { return { type: alertrowConstants.GETBYID_ALERTROW_SUCCESS, alertrow } }
    function failure(error) { return { type: alertrowConstants.GETBYID_ALERTROW_FAILURE, error } }
}
/**
 * Update
 * @param {*} id
 */
function update(id,data) {
    return dispatch => {
        dispatch(request(data));

        new AlertRowService().update(id,data)
                        .then(
                            alertrow => {
                                dispatch(success(alertrow));
                                dispatch(alertActions.success(resources.SAVE_SUCCESS));
                            },
                            error => {
                                dispatch(failure(error, data));
                                dispatch(alertActions.error(resources.SAVE_FAILED + ': ' + error));
                            }
                        );
    };

    function request(alertrow) { return { type: alertrowConstants.UPDATE_ALERTROW_REQUEST, alertrow } }
    function success(alertrow) { return { type: alertrowConstants.UPDATE_ALERTROW_SUCCESS, alertrow } }
    function failure(error, alertrow) { return { type: alertrowConstants.UPDATE_ALERTROW_FAILURE, error, alertrow } }
}

/**
 * Delete
 * @param {*} id
 */
function remove(id) {
    return dispatch => {
        dispatch(request());

        new AlertRowService().remove(id)
                        .then(
                            alertrow => {
                                dispatch(success(alertrow));
                                dispatch(alertActions.success(resources.DELETE_SUCCESS));
                            },
                            error => {
                                dispatch(failure(error));
                                dispatch(alertActions.error(resources.DELETE_FAILED + ': ' + error));
                            }
                        );
    };

    function request() { return { type: alertrowConstants.REMOVE_ALERTROW_REQUEST, id } }
    function success() { return { type: alertrowConstants.REMOVE_ALERTROW_SUCCESS, id } }
    function failure(error) { return { type: alertrowConstants.REMOVE_ALERTROW_FAILURE, error } }
}

export const distributionpointConstants = {
    CREATE_DISTRIBUTIONPOINT_REQUEST: 'CREATE_DISTRIBUTIONPOINT_REQUEST',
    CREATE_DISTRIBUTIONPOINT_SUCCESS: 'CREATE_DISTRIBUTIONPOINT_SUCCESS',
    CREATE_DISTRIBUTIONPOINT_FAILURE: 'CREATE_DISTRIBUTIONPOINT_FAILURE',
    
    GET_DISTRIBUTIONPOINT_REQUEST: 'GET_DISTRIBUTIONPOINT_REQUEST',
    GET_DISTRIBUTIONPOINT_SUCCESS: 'GET_DISTRIBUTIONPOINT_SUCCESS',
    GET_DISTRIBUTIONPOINT_FAILURE: 'GET_DISTRIBUTIONPOINT_FAILURE',
    
    GETBYID_DISTRIBUTIONPOINT_REQUEST: 'GETBYID_DISTRIBUTIONPOINT_REQUEST',
    GETBYID_DISTRIBUTIONPOINT_SUCCESS: 'GETBYID_DISTRIBUTIONPOINT_SUCCESS',
    GETBYID_DISTRIBUTIONPOINT_FAILURE: 'GETBYID_DISTRIBUTIONPOINT_FAILURE',
    
    UPDATE_DISTRIBUTIONPOINT_REQUEST: 'UPDATE_DISTRIBUTIONPOINT_REQUEST',
    UPDATE_DISTRIBUTIONPOINT_SUCCESS: 'UPDATE_DISTRIBUTIONPOINT_SUCCESS',
    UPDATE_DISTRIBUTIONPOINT_FAILURE: 'UPDATE_DISTRIBUTIONPOINT_FAILURE',
    
    REMOVE_DISTRIBUTIONPOINT_REQUEST: 'REMOVE_DISTRIBUTIONPOINT_REQUEST',
    REMOVE_DISTRIBUTIONPOINT_SUCCESS: 'REMOVE_DISTRIBUTIONPOINT_SUCCESS',
    REMOVE_DISTRIBUTIONPOINT_FAILURE: 'REMOVE_DISTRIBUTIONPOINT_FAILURE'

};
